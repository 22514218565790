import React from "react";
import styles from "./icon-input.module.css";

const IconInput = ({
  icon,
  label,
  type = "text",
  value,
  onChange,
  placeholder,
  borderBottomWidth = 1,
  ...inputProps
}) => {
  return (
    <div className={styles.inputCover} style={{ borderBottomWidth }}>
      <div className={styles.iconCover}>
        {icon && <img src={icon} alt={label} />}
      </div>
      <input
        className={styles.input}
        id={label}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...inputProps}
      />
    </div>
  );
};

export default IconInput;
