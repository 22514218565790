import { createSlice } from "@reduxjs/toolkit";
import { getConnections } from "./connections.async";

const initialState = {
  loading: false,
  connections: [],
};

const connectionsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    resetConnections(state) {
      state.loading = false;
      state.connections = [];
    },
  },
  extraReducers: {
    [getConnections.pending]: (state) => {
      state.loading = true;
    },
    [getConnections.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    [getConnections.fulfilled]: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload?.data)) {
        state.connections = [...action.payload.data];
      }
    },
  },
});

export const { resetConnections } = connectionsSlice.actions;
export default connectionsSlice.reducer;
