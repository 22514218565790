import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, PATH } from "services/axios";

// Get Dashboard
export const getDashboard = createAsyncThunk(
  "dashboard/getDashboard",
  async () => {
    return await API.get(PATH.getDashboard);
  }
);
