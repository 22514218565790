import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import SearchPage from "components/search-page";
import styles from "./leads.module.css";
import LeadsCard from 'components/leads-card';


const Leads = () => {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [search, setSearch] = useState("");

 
  if(user && !user.leads) {
    window.location.pathname="/";
  }

  return (
    <div className={styles.wrapper}>
      <SearchPage title="Leads" search={search} setSearch={setSearch} />
      <Row>
        <Col>
          <LeadsCard
            isSearchBar={false}
            extraSearch={search}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Leads;
