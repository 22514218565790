import { colors } from "styles/theme";
import { capitalize } from "utils";
import styles from "./label-select.module.css";

const LabelSelect = ({
  label,
  value,
  onChange,
  placeholder,
  classes = "mt-3",
  labelColor = "rgba(35, 49, 75, 0.65)",
  selectHeight = "48px",
  labelFontSize = "16px",
  options = [],
  ...selectProps
}) => {
  return (
    <div className={classes}>
      <p
        className={styles.label}
        style={{ color: labelColor, fontSize: labelFontSize }}
      >
        {label}
      </p>
      <div className={styles.selectBox}>
        <select
          className={styles.select}
          style={{
            fontSize: labelFontSize,
            color: Boolean(value) ? colors.tableText : colors.appPlaceholder,
          }}
          value={value}
          onChange={onChange}
          {...selectProps}
        >
          <option value="" className={styles.optionPlaceholder}>
            {placeholder}
          </option>
          {options.map((opt, idx) => (
            <option key={idx} value={opt.id}>
              {capitalize(opt.name)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LabelSelect;
