import { useState } from "react";
import { Card, Col, Row, TabContent, TabPane } from "reactstrap";
import styles from "./orders.module.css";
import SearchPage from "components/search-page";
import OrdersTabBar from "components/orders-tabbar";
import OrdersTable from "components/orders-table";

const Orders = () => {
  const [activeTab, setActiveTab] = useState("upcoming"); // "upcoming" || "past"
  return (
    <div className={styles.wrapper}>
      <SearchPage title="Orders" />

      <OrdersTabBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleIssue={() => console.log("issue")}
      />

      <TabContent activeTab={activeTab}>
        <TabPane tabId="upcoming">
          <Row>
            <Col>
              <Card className="border-0 mt-md-4 mt-3">
                <OrdersTable />
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="past">
          <Row>
            <Col>
              <Card className="border-0 mt-md-4 mt-3">
                <OrdersTable />
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Orders;
