import React, { useState } from "react";
import styles from "./settings.module.css";
import SearchPage from "components/search-page";
import { Col, Row } from "reactstrap";
import ProfileEdit from "components/profile-edit";
import ProfileActivity from "components/profile-activity";
import ModalEmpty from "components/modal-empty";
import ChangePasswordCard from "components/change-password-card";
import { shallowEqual, useSelector } from "react-redux";

const Settings = () => {
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.wrapper}>
      <ModalEmpty isOpen={isOpen} setIsOpen={setIsOpen}>
        <ChangePasswordCard setIsOpen={setIsOpen} />
      </ModalEmpty>

      <SearchPage title="Settings" isSearchBar={false}>
       
      </SearchPage>

      <Row>
        <Col md={4}>
          <ProfileEdit setIsOpen={setIsOpen} user={user} />
        </Col>
        {/* <Col md={9}> */}
          {/* <ProfileActivity /> */}
        {/* </Col> */}
      </Row>
    </div>
  );
};

export default Settings;
