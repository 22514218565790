import VendorUsers from 'components/vendor-users';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { API, PATH } from 'services/axios';
import styles from './VendorsEmployees.module.css'
import cls from "classnames";
import SwitchInput from 'components/switch-input';
import SearchPage from 'components/search-page';
import { Card } from 'reactstrap';
import { Table } from 'antd';
import plusImg from "assets/icons/plus-white.svg";

const VendorsEmployees = () => {
    const { user } = useSelector((state) => state.auth, shallowEqual);
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const navToAdd = () => {
        navigate(`/vendors/${user.uid}/add`);
    };

    const navToUserDetails = (e, uid) => {
        e.stopPropagation();
        navigate(`/users/${uid}`);
    };


    const getVendorDetails = async () => {
        try {
            const result = await API.get(PATH.getVendorEmployees(user.uid));
            console.log(result);
            if (result) {
                console.log(result);
                setData(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (user.uid) { getVendorDetails(); }
    }, [user.uid]);

    // console.log(uid);

    const columns = [
        {
            title: 'Name',
            render: ({ uid, firstname, lastname, photo }) => <div
                onClick={(e) => navToUserDetails(e, uid)}
                className="d-flex align-items-center pointer"
            >
                {photo && photo != "null" ? (
                    <img src={photo} alt={firstname} className={styles.avatar} />
                ) : (
                    <div className={styles.avatar} />
                )}
                <p className="m-0" style={{ whiteSpace: 'nowrap' }}>{firstname} {lastname}</p>
            </div>,
            sorter: (a, b) => a.firstname.localeCompare(b.firstname),
        },
        {
            title: 'Email ID',
            dataIndex: 'email',
        },
        {
            title: 'Links',
            dataIndex: 'links',
            sorter: (a, b) => parseFloat(a.links) - parseFloat(b.links),
        },
        {
            title: 'Pro',
            render: ({ isPro }) => <div className={
                Boolean(isPro) ?
                    (cls(styles.proDiv, { [styles.active]: true }))
                    : (cls(styles.proDiv, { [styles.inactive]: true }))
            }>
                <span>{Boolean(isPro) ? 'True' : 'False'}</span>
            </div>,
            filters: [
                {
                    text: 'True',
                    value: 1,
                },
                {
                    text: 'False',
                    value: 0,
                },
            ],
            onFilter: (value, record) => record.isPro === value,
        },
        {
            title: 'Active',
            render: ({ active }) => <SwitchInput
                checked={Boolean(active)}
            />,
            filters: [
                {
                    text: 'active',
                    value: 1,
                },
                {
                    text: 'Inactive',
                    value: 0,
                },
            ],
            onFilter: (value, record) => record.active === value,
        },
        // {
        //   title: ' ',
        //   render: () => <img src={moreImg} alt="more" />,
        // },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
        setSearchParams({
            page: pagination.current,
            size: pagination.pageSize
        });
    };
    return (
        <>
            <div className={styles.wrapper}>
                <SearchPage
                    title="Employees"
                // search={search}
                // setSearch={setSearch}
                >
                    <button onClick={navToAdd} className={styles.addBtn}>
                        <img src={plusImg} alt="plus" /> Add User
                    </button>
                </SearchPage>

                <Card className="border-0">

                    <Table
                        className="mt-3 p-3 overflow-auto"
                        columns={columns}
                        // dataSource={searchData}
                        dataSource={data}
                        onChange={onChange}
                        pagination={{
                            defaultPageSize: Number(searchParams.get('size')) || 10,
                            defaultCurrent: Number(searchParams.get('page')) || 1,
                        }}
                    />

                </Card>

            </div>
        </>
    );
};

export default VendorsEmployees;