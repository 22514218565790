import React, { useState, useMemo, useEffect } from "react";
import { Card } from "reactstrap";
import cls from "classnames";
import moment from "moment";
import CardSearchBar from "components/card-searchbar";
import styles from "./subscriptions-card.module.css";
import { trimString } from "utils";
import { Table } from 'antd';
import { useDispatch } from "react-redux";
import { getSales } from "redux/slices/sales/sales.async";
import { useSearchParams } from "react-router-dom";

const SubscriptionsCard = ({
  isSearchBar,
  subscriptions,
  extraSearch = "",
}) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearch(extraSearch);
  }, [extraSearch]);

  // START LOCAL SEARCH
  const searchForData = (arr, s) => {
    s = trimString(s);
    return arr.filter((item, index) => {
      return item.name.toLowerCase() && item.name.toLowerCase().includes(s);
    });
  };

  // const searchData = useMemo(() => {
  //   return search.length > 0
  //     ? searchForData(subscriptions, search.toLowerCase())
  //     : subscriptions;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search, subscriptions?.length]);
  // END LOCAL SEARCH

  const columns = [
    {
      title: 'Customer',
      render: ({ name }) => <span style={{ whiteSpace: 'nowrap' }}>{name}</span>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Purchase Date',
      render: ({ purchaseDate }) => <span style={{ whiteSpace: 'nowrap' }}>{moment(purchaseDate).format("DD MMM, YYYY")}</span>,
      sorter: (a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate),
    },
    {
      title: 'Expiry Date',
      render: ({ expiryDate }) => <span style={{ whiteSpace: 'nowrap' }}>{moment(expiryDate).format("DD MMM, YYYY")}</span>,
      sorter: (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      sorter: (a, b) => parseFloat(a.price.slice(1)) - parseFloat(b.price.slice(1)),
    },
    {
      title: 'Duration',
      render: ({ productId }) => <td>
        {productId?.includes("30") ? 1 : 12} Month
        {!productId?.includes("30") && "s"}
      </td>,
      filters: [
        {
          text: '1 Month',
          value: true,
        },
        {
          text: '12 Months',
          value: false,
        },
      ],
      onFilter: (value, record) => record.productId?.includes("30") === value,
    },
    {
      title: 'Status',
      render: ({ status, expiryDate }) => <div
        className={cls(
          styles.status,
          status === 1 &&
            moment(expiryDate).isAfter(moment(new Date()))
            ? styles.active
            : styles.inactive
        )}
      >
        {status === 1 &&
          moment(expiryDate).isAfter(moment(new Date()))
          ? "Active"
          : "Expired"}
      </div>,
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Expired',
          value: false,
        },
      ],
      onFilter: (value, record) => (record.status === 1 && moment(record.expiryDate).isAfter(moment(new Date()))) === value,
    },
  ];


  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setSearchParams({
      page: pagination.current,
      size: pagination.pageSize
    });
    dispatch(getSales({
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize
    }));
  };

  return (
    <Card className={styles.card}>
      <CardSearchBar
        title="Subscriptions"
        isSearchBar={isSearchBar}
        classes="mx-0"
        search={search}
        setSearch={setSearch}
      />

      <Table
        className="mt-3 overflow-auto"
        columns={columns}
        dataSource={search.length > 0
          ? searchForData(subscriptions, search.toLowerCase())
          : subscriptions}
        onChange={onChange}
        pagination={{
          defaultPageSize: Number(searchParams.get('size')) || 10,
          defaultCurrent: Number(searchParams.get('page')) || 1,
          total: subscriptions ? subscriptions[0]?.total : 10
        }}
      />

    </Card>
  );
};

export default SubscriptionsCard;
