import { useState, useEffect, useRef } from "react";
import { Col, Row } from "reactstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CheckboxInput from "components/checkbox-input";
import CollapseCard from "components/collapse-card";
import SearchPage from "components/search-page";
import SwitchInput from "components/switch-input";
import styles from "./send-notification.module.css";
import NotificationPreview from "components/notification-preview";
import { getUsers } from "redux/slices/users/users.aync";
import { resetUsers } from "redux/slices/users/users.slice";
import notify from "services/toastify";
import { sendNotification } from "redux/slices/campaigns/campaigns.async";
import CountCard from "components/count-card";
import { colors } from "styles/theme";

import usersImg from "assets/icons/users-circle.svg";
import { getGroups } from "redux/slices/groups/groups.async";
import { Table } from "antd";
import { useSearchParams } from "react-router-dom";

const limit = 10;

const SendNotification = () => {
  const { data } = useSelector((state) => state.users, shallowEqual);
  const { data: groupData, loading: groupLoading } = useSelector(
    (state) => state.groups,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isNotificationCard, setIsNotificationCard] = useState(true);

  const [groupSelected, setGroupSelected] = useState([]);
  const [selected, setSelected] = useState([]);
  const [notificationData, setNotificationData] = useState(null);

  // START INFINITE SCROLL
  const offset = useRef(0);

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getUsers({ offset: offset.current, limit }));

    return () => {
      dispatch(resetUsers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // END INFINITE SCROLL

  const finalUsers = () => {
    const arr = [...selected];
    if (groupSelected.length > 0) {
      for (const item of groupSelected) {
        // arr.push(...item.users);
        for (const user of item.users) {
          arr.push(user.uid);
        }
      }
    }

    return [...new Set(arr)];
  };

  const handleSendNotification = () => {
    const validConditions =
      notificationData && (selected.length > 0 || groupSelected.length > 0);
    if (!validConditions) {
      notify("Please enter notification title, text and recipients!!!", 0);
      return;
    }

    setIsLoading(true);
    const reqData = { ...notificationData, users: finalUsers() };
    console.log('reqData', reqData);
    dispatch(sendNotification(reqData)).then(({ error }) => {
      setIsLoading(false);
      if (error) {
        console.log(error);
        notify("Notification send failed!!!", 0);
      } else {
        setSelected([]);
        notify("Notification sent successfully");
      }
    });
  };

  const handleNotificationData = (res) => {
    setNotificationData(res);
    setIsNotificationCard(false);
  };

  const isGroupExist = (id) => {
    const idx = groupSelected.findIndex((x) => x.id === id);
    if (idx === -1) {
      return false;
    } else {
      return true;
    }
  };

  const handleGroupSelect = (item) => {
    const arr = [...groupSelected];
    const idx = arr.findIndex((x) => x.id === item.id);
    if (idx === -1) {
      arr.push(item);
    } else {
      arr.splice(idx, 1);
    }
    setGroupSelected(arr);
  };

  const isExist = (uid) => {
    const idx = selected.findIndex((x) => x === uid);
    if (idx === -1) {
      return false;
    } else {
      return true;
    }
  };

  const handleSelectAll = () => {
    if (selected.length === data?.length) {
      setSelected([]);
    } else {
      const arr = [];
      for (const item of data) {
        arr.push(item.uid);
      }
      setSelected(arr);
    }
  };

  const handleSelect = (uid) => {
    const arr = [...selected];
    const idx = arr.findIndex((x) => x === uid);
    if (idx === -1) {
      arr.push(uid);
    } else {
      arr.splice(idx, 1);
    }
    setSelected(arr);
  };


  const columns = [
    {
      title: ' ',
      render: ({ uid }) => <CheckboxInput
        name={uid}
        checked={isExist(uid)}
        setChecked={() => handleSelect(uid)}
      />
    },
    {
      title: 'Name',
      render: ({ name, photo }) => <div className="d-flex align-items-center">
        {photo && photo != "null" ? (
          <img src={photo} alt={name} className={styles.avatar} />
        ) : (
          <div className={styles.avatar} />
        )}
        <p className="mb-0">{name}</p>
      </div>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
    },
    {
      title: 'Location',
      render: ({ location }) => <span>{location ? location : '--'}</span>
    },
    {
      title: 'Pro',
      render: ({ isPro, uid }) => <SwitchInput
        name={uid}
        checked={Boolean(isPro)}
        setChecked={() => { }}
      />,
      filters: [
        {
          text: 'True',
          value: 1,
        },
        {
          text: 'False',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.isPro === value,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setSearchParams({
      page: pagination.current,
      size: pagination.pageSize
    });
    dispatch(getUsers({
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize
    }));
  };


  return (
    <div className={styles.wrapper}>
      <SearchPage title="Send Notification" isGoBack />

      <NotificationPreview
        isOpen={isNotificationCard}
        setIsOpen={setIsNotificationCard}
        handleNotificationData={handleNotificationData}
      />

      <CollapseCard
        isOpen={!isNotificationCard}
        setIsOpen={setIsNotificationCard}
        title="Users List"
        btnText="Send Notification"
        contentClass={styles.usersContent}
        onBtnClick={handleSendNotification}
        loading={isLoading}
      >
          <div className="mx-3 d-flex flex-wrap gap-3">
          {groupData?.length > 0 &&
            groupData.map((item) => (
              <div
                key={item.id}
                onClick={() => handleGroupSelect(item)}
                className="pointer"
              >
                <CountCard
                  photo={usersImg}
                  name={item.name}
                  count={item.users?.length}
                  backgroundColor={colors.whiteBg}
                  isSelected={isGroupExist(item.id)}
                />
              </div>
            ))}
        </div>

        <Table
          className="mt-3 p-3 overflow-auto"
          columns={columns}
          dataSource={data}
          onChange={onChange}
          pagination={{
            defaultPageSize: Number(searchParams.get('size')) || 10,
            defaultCurrent: Number(searchParams.get('page')) || 1,
            total: data ? data[0]?.total : 10
          }}
        />
      </CollapseCard>
    </div>
  );
};

export default SendNotification;
