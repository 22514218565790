import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyARyx5TVIzHTOmVaUkXz0dMYO7gDgnsS0Q",
    authDomain: "netwotapz.firebaseapp.com",
    projectId: "netwotapz",
    storageBucket: "netwotapz.appspot.com",
    messagingSenderId: "308035381363",
    appId: "1:308035381363:web:a9423c50c16e73d1c4e206",
    measurementId: "G-NFPB6YFTV0"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;