import { Card, Collapse } from "reactstrap";
import cls from "classnames";
import styles from "./collapse-card.module.css";

import chevronDown from "assets/icons/chevron-down.svg";
import chevronRight from "assets/icons/chevron-right.svg";

const CollapseCard = ({
  title,
  btnText,
  onBtnClick,
  contentClass = styles.content,
  isOpen,
  setIsOpen,
  loading,
  children,
}) => {
  return (
    <Card className={styles.card}>
      <div onClick={() => setIsOpen(!isOpen)} className={styles.header}>
        <p className="mb-0">{title}</p>
        <img src={isOpen ? chevronDown : chevronRight} alt="Show Hide" />
      </div>

      <Collapse isOpen={isOpen}>
        <div className={contentClass}>{children}</div>
        <button
          disabled={loading}
          onClick={onBtnClick}
          className={cls(styles.submitBtn, { [styles.disabled]: loading })}
        >
          {btnText}
        </button>
      </Collapse>

    </Card>
  );
};

export default CollapseCard;
