import React, { useState, useMemo, useEffect } from "react";
import { Card } from "reactstrap";
import moment from "moment";
import CardSearchBar from "components/card-searchbar";
import styles from "./leads-card.module.css";
import { trimString } from "utils";
import { API } from 'services/axios';
import { Table } from "antd";

const LeadsCard = ({
  isSearchBar,
  subscriptions,
  extraSearch = "",
}) => {
  const [search, setSearch] = useState("");
  const [leads, setLeads] = useState([]);
  useEffect(() => {
    API.get('/leads').then(res => {
      setLeads(res?.data);
    });
  }, []);

  useEffect(() => {
    setSearch(extraSearch);
  }, [extraSearch]);

  // START LOCAL SEARCH
  const searchForData = (arr, s) => {
    s = trimString(s);
    return arr.filter((item, index) => {
      return item.name.toLowerCase() && item.name.toLowerCase().includes(s);
    });
  };

  const searchData = useMemo(() => {
    return search.length > 0
      ? searchForData(leads, search.toLowerCase())
      : leads;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, leads]);
  // END LOCAL SEARCH


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width:'20%'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width:'20%'
    },
    {
      title: 'Phone',
      render: ({ phone }) => <span style={{ whiteSpace: 'nowrap' }}>+{phone}</span>,
      width:'15%'
    },
    {
      title: 'Message',
      dataIndex: 'message',
      width:'35%'
    },
    {
      title: 'Date',
      render: ({ createdAt }) => <span style={{ whiteSpace: 'nowrap' }}>{moment(createdAt).format("DD MMM, YYYY")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      width:'10%'
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };


  return (
    <Card className={styles.card}>
      <CardSearchBar
        title="Leads"
        isSearchBar={isSearchBar}
        classes="mx-0"
        search={search}
        setSearch={setSearch}
      />

      <Table
        className="mt-3 p-3 overflow-auto"
      columns={columns}
      dataSource={searchData}
      onChange={onChange}
      />
      
    </Card>
  );
};

export default LeadsCard;
