import React from "react";
import styles from "./card-searchbar.module.css";

import searchImg from "assets/icons/search-blue.svg";
import plusImg from "assets/icons/plus-white.svg";

const CardSearchBar = ({
  title,
  isSearchBar = true,
  search,
  setSearch,
  isRightBtn = false,
  rightBtnText2,
  handleRightBtn2,
  rightBtnText,
  handleRightBtn,
  classes = "mx-3",
}) => {
  return (
    <div className={`${styles.card} ${classes}`}>
      {title && <p className={styles.title}>{title}</p>}

      <div
        className={`d-flex align-items-center ${!Boolean(title) && "justify-content-between w-100"}`}
      >
        {isSearchBar && (
          <div className={styles.searchBar}>
            <img src={searchImg} alt="Search" className={styles.searchImg} />
            <input
              type="text"
              placeholder="Search here"
              className={styles.searchInput}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        )}
        <div className="d-flex align-items-center">
          {isRightBtn && (
            <button onClick={handleRightBtn2} className={styles.addBtn2}>
              {rightBtnText2}
            </button>
          )}
          {Boolean(rightBtnText) && (
            <button onClick={handleRightBtn} className={styles.addBtn}>
              <img src={plusImg} alt="plus" /> {rightBtnText}
            </button>
          )}
        </div>

      </div>
    </div>
  );
};

export default CardSearchBar;
