import styles from "./checkbox-input.module.css";

const CheckboxInput = ({ name, checked, setChecked }) => {
  return (
    <>
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        className={styles.inputCheckbox}
        onChange={(e) => setChecked(!e.target.checked)}
      />
      <nav className={styles.nav}>
        <label htmlFor={name} className={styles.checkbox}></label>
      </nav>
    </>
  );
};

export default CheckboxInput;
