import React from "react";
import { Card } from "reactstrap";
import styles from "./user-created-card.module.css";

import successImg from "../../assets/icons/tick-success.svg";
import copyImg from "../../assets/icons/copy-primary.svg";

const UserCreatedCard = () => {
  return (
    <Card className={styles.card}>
      <div className={styles.cardHeader}>
        <img src={successImg} alt="Success" />
        <p>User created successfully</p>
      </div>
      <div className={styles.inputCover}>
        <p>johndoe@gmail.com</p>
        <div className={styles.copyBtn}>
          <img src={copyImg} alt="Copy" />
          <p>Copy</p>
        </div>
      </div>
      <div className={styles.inputCover}>
        <p>********</p>
        <div className={styles.copyBtn}>
          <img src={copyImg} alt="Copy" />
          <p>Copy</p>
        </div>
      </div>

      <button className={styles.sendBtn}>Send Email Instructions</button>
      <p className={styles.btnText}>Cancel</p>
    </Card>
  );
};

export default UserCreatedCard;
