import React from "react";
import { Card } from "reactstrap";
import styles from "./user-links-card.module.css";
import { urls } from '../../data/linksData';

const UserLinksCard = ({ linkTaps }) => {

  const getSocialIcon = (name) => {
    const urlItem = urls.filter(
      (x) => x.name.toLowerCase() === name.toLowerCase()
    )[0];
    return urlItem?.icon || null;
  };

  return (
    <Card className={styles.card}>
      <p className={styles.header}>
        User Analytics <span>(Links)</span>
      </p>
      {/* <p className={styles.title}>Social Networks</p> */}
      <div className={styles.listBox}>
      {linkTaps && linkTaps.map((item) => (
        <div key={item.id} className={styles.list}>
          <div className="d-flex align-items-center">
            <img src={item.icon ? item.icon : getSocialIcon(item.type)} alt={item.name} className={styles.listIcon} />
            <p className={styles.listName}>{item.type}</p>
          </div>
          <p className={styles.listTab}>
            {item.taps} <span>Taps</span>
          </p>
        </div>
      ))}
      </div>
    </Card>
  );
};

export default UserLinksCard;
