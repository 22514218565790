import SwitchInput from "components/switch-input";
import React from "react";
import { Card } from "reactstrap";
import CollapseList from "./CollapseList";
import styles from "./user-products-card.module.css";

const UserProductsCard = ({ categories }) => {
  return (
    <Card className={styles.card}>
      <p className={styles.header}>
        User Products <span>(Payment Method: Online)</span>
      </p>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <p className={styles.title}>Products</p>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-3">
            <p className={styles.label}>Table</p>
            <SwitchInput />
          </div>
          <div className="d-flex align-items-center">
            <p className={styles.label}>Delivery</p>
            <SwitchInput />
          </div>
        </div>
      </div>
      {/* Products */}
      {categories?.map(cat => (<CollapseList key={cat.id} category={cat} />))}
    </Card>
  );
};

export default UserProductsCard;
