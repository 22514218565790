import { useState, useRef } from "react";
import { Card } from "reactstrap";
import styles from "./qr-links.module.css";
import SearchPage from "components/search-page";
import LabelInput from "components/label-input";
import LabelSelect from "components/label-select";
import CardButtons from "components/card-buttons";
import { colors } from "styles/theme";
import SwitchInput from "components/switch-input";

import cameraImg from "assets/icons/camera-black.svg";
import proImg from "assets/icons/pro-circle.png";

const linkTypes = [
  { id: 1, name: "facebook" },
  { id: 2, name: "twitter" },
  { id: 3, name: "instagram" },
];

const AddLink = () => {
  const imgRef = useRef();
  const [linkType, setLinkType] = useState("");
  const [photo, setPhoto] = useState(null);

  const handleChange = (e) => {
    // console.log(e.target);
    setLinkType(e.target.value);
  };

  const handleImgChange = (e) => {
    const { files } = e.target;

    if (files.length > 0) {
      setPhoto(files[0]);
    }
  };

  return (
    <div className={styles.wrapper}>
      <input
        className="d-none"
        type="file"
        accept="image/jpeg,image/jpg,image/png"
        onChange={handleImgChange}
        name="doc"
        ref={imgRef}
      />

      <SearchPage title="Add Link" isGoBack />

      <Card className={styles.addCard}>
        <div className="d-flex justify-content-between mb-3">
          <div
            onClick={() => imgRef.current.click()}
            className="d-flex align-items-center"
          >
            <div className={styles.photoCover}>
              {photo ? (
                <img
                  src={URL.createObjectURL(photo)}
                  alt="Profile pic"
                  className={styles.profilePic}
                />
              ) : (
                <img src={cameraImg} alt="camera" />
              )}
            </div>
            <div className={styles.changeIconBtn}>
              Change Icon
              <img src={proImg} alt="pro" />
            </div>
          </div>
          <div className={styles.switchDiv}>
            <p>Highlight</p>
            <SwitchInput />
          </div>
        </div>

        <LabelInput
          labelColor={colors.tableText}
          label="Link Title"
          placeholder="Enter link title"
          isRequired={false}
          labelFontSize="14px"
          inputHeight="37px"
        />
        <LabelSelect
          labelColor={colors.tableText}
          label="Link Type"
          placeholder="Select link type"
          labelFontSize="14px"
          selectHeight="37px"
          options={linkTypes}
          value={linkType}
          onChange={handleChange}
        />
        <LabelInput
          labelColor={colors.tableText}
          label="Link Username"
          placeholder="Enter link username"
          isRequired={false}
          labelFontSize="14px"
          inputHeight="37px"
        />

        <CardButtons />
      </Card>
    </div>
  );
};

export default AddLink;
