export const colors = {
  white: " #fff",
  black: " #000",
  primary: " #605bff",
  secondary: " #030229",
  whiteBg: " #f6f6f6",
  tableText: " #23314b",
  appActive: " #5b93ff",
  appInactive: " #8b8b8b",
  appDanger: " #ff8f6b",
  appPlaceholder: "rgba(35, 49, 75, 0.4)",
  appRed: "#D11A2A",
};

export const fonts = {
  pop: "Poppins, sans-serif",
  inter: "Inter, sans-serif",
  nunito: "Nunito, sans-serif",
};
