import { Outlet, Navigate } from "react-router-dom";
import HomeLayout from "layouts/Home";
import axios from 'axios';

const PrivateRoute = ({ user }) => {
  if (user) {
    console.log(user);
    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
    return (
      <HomeLayout>
        <Outlet />
      </HomeLayout>
    );
  }

  if (!user) return <Navigate replace to="login" />;
};

export default PrivateRoute;
