import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import cls from "classnames";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import SearchPage from "components/search-page";
import UsersTable from "components/users-table";
import styles from "./group-details.module.css";
import ModalEmpty from "components/modal-empty";
import CreateEditGroupCard from "components/create-edit-group-card";
import ConfirmCard from "components/confirm-card";
import {
  getGroupDetails,
  deleteUsersFromGroup,
  deleteGroup,
} from "redux/slices/groups/groups.async";
import CardSearchBar from "components/card-searchbar";
import { updateUsersInDetails } from "redux/slices/groups/groups.slice";
import notify from "services/toastify";

const GroupDetails = () => {
  const { details, loading } = useSelector(
    (state) => state.groups,
    shallowEqual
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isGroupOpen, setIsGroupOpen] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getGroupDetails({ id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleRemoveUsers = () => {
    setIsLoading(true);
    dispatch(deleteUsersFromGroup({ id, users: selected }))
      .then((res) => {
        console.log("Res", res);
        setIsLoading(false);
        const { status, message } = res.payload;
        if (status) {
          dispatch(updateUsersInDetails(selected));
          setSelected([]);
        } else {
          notify(message, 0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Err", err);
        notify("Something went wrong!!!", 0);
      });
  };

  const handleDeleteGroup = () => {
    setIsLoading(true);
    dispatch(deleteGroup({ id }))
      .then((res) => {
        console.log("Res", res);
        setIsLoading(false);
        const { status, message } = res.payload;
        if (status) {
          setIsConfirmOpen(false);
          navigate(-1);
        } else {
          notify(message, 0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Err", err);
        notify("Something went wrong!!!", 0);
      });
  };

  return (
    <div className={styles.wrapper}>
      <ModalEmpty
        paddingTop="25vh"
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      >
        <ConfirmCard
          handleSave={handleDeleteGroup}
          setIsOpen={setIsConfirmOpen}
        />
      </ModalEmpty>

      <ModalEmpty
        paddingTop="25vh"
        isOpen={isGroupOpen}
        setIsOpen={setIsGroupOpen}
      >
        <CreateEditGroupCard
          id={id}
          name={details?.name}
          title="Edit Group"
          setIsOpen={setIsGroupOpen}
        />
      </ModalEmpty>

      <SearchPage title={details?.name} isGoBack isSearchBar={false}>
        <div className="d-flex align-items-center">
          {selected.length > 0 && (
            <button
              disabled={isLoading}
              onClick={handleRemoveUsers}
              className={styles.removeBtn}
            >
              Remove from group
            </button>
          )}
          <button
            onClick={() => setIsGroupOpen(true)}
            className={cls(styles.btn, styles.edit)}
          >
            Edit
          </button>
          <button
            onClick={() => setIsConfirmOpen(true)}
            className={cls(styles.btn, styles.delete)}
          >
            Delete
          </button>
        </div>
      </SearchPage>
      <Card className={styles.card}>
        <CardSearchBar />
        <UsersTable
          data={details?.users}
          selected={selected}
          setSelected={setSelected}
        />
      </Card>
    </div>
  );
};

export default GroupDetails;
