import React, { useState, useEffect } from "react";
// import { Table } from "reactstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import SwitchInput from "components/switch-input";
import CheckboxInput from "components/checkbox-input";
import styles from "./users-table.module.css";

import moreImg from "assets/icons/more.svg";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  updateUserIsPro,
  updateUserActive,
  getUsers,
} from "redux/slices/users/users.aync";
import notify from "services/toastify";
import { resetCall, updateUsers } from "redux/slices/users/users.slice";
import { Table } from "antd";
import moment from "moment";

const UsersTable = ({
  classes = "",
  data = [],
  selected = [],
  setSelected,
}) => {
  const { isSuccess, isError } = useSelector(
    (state) => state.users,
    shallowEqual
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentData, setCurrentData] = useState(null);

  // useEffect(() => {
  //   if (!searchParams.get('page')) {
  //     setSearchParams({
  //       page: 1,
  //       size: 100
  //     })
  //   }
  // }, []);


  useEffect(() => {
    if (isError) {
      dispatch(resetCall());
      notify(isError, 0);
    } else if (isSuccess) {
      dispatch(resetCall());
      notify(isSuccess);
      if (currentData) {
        const newArr = data.map((x) =>
          x.id === currentData.id ? currentData : x
        );
        // console.log(newArr);
        setCurrentData(null);
        dispatch(updateUsers(newArr));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  const handleUpdateUser = (rec, isActiveUpdate) => {
    let newData;
    if (isActiveUpdate) {
      newData = { ...rec, active: rec.active ? 0 : 1 };
      const reqData = {
        uid: rec.uid,
        active: rec.active ? false : true,
      };
      dispatch(updateUserActive(reqData));
    } else {
      // isPro
      newData = { ...rec, isPro: rec.isPro ? 0 : 1 };
      const reqData = {
        uid: rec.uid,
        isPro: rec.isPro ? 0 : 1,
      };
      dispatch(updateUserIsPro(reqData));
    }
    setCurrentData(newData);
  };

  const isExist = (uid) => {
    const idx = selected.findIndex((x) => x === uid);
    if (idx === -1) {
      return false;
    } else {
      return true;
    }
  };

  const handleSelectAll = () => {
    if (selected.length === data?.length) {
      setSelected([]);
    } else {
      const arr = [];
      for (const item of data) {
        arr.push(item.uid);
      }
      setSelected(arr);
    }
  };

  const handleSelect = (uid) => {
    const arr = [...selected];
    const idx = arr.findIndex((x) => x === uid);
    if (idx === -1) {
      arr.push(uid);
    } else {
      arr.splice(idx, 1);
    }
    setSelected(arr);
  };

  const navToUserDetails = (e, uid) => {
    e.stopPropagation();
    navigate(`/users/${uid}`);
  };




  const columns = [
    {
      title: ' ',
      render: ({ uid }) => <CheckboxInput
        name={uid}
        checked={isExist(uid)}
        setChecked={() => handleSelect(uid)}
      />
    },
    {
      title: 'Name',
      render: ({ uid, name, photo }) => <div
        onClick={(e) => navToUserDetails(e, uid)}
        className="d-flex align-items-center pointer"
      >
        {photo && photo != "null" ? (
          <img src={photo} alt={name} className={styles.avatar} />
        ) : (
          <div className={styles.avatar} />
        )}
        <p className="m-0" style={{ whiteSpace: 'nowrap' }}>{name}</p>
      </div>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
    },
    {
      title: 'Links',
      dataIndex: 'linksCount',
      sorter: (a, b) => parseFloat(a.linksCount) - parseFloat(b.linksCount),
    },
    {
      title: 'Pro',
      render: ({ uid, isPro, id, photo, name, email, links, active, linksCount }) => <SwitchInput
        name={`user_isPro_${id}`}
        checked={Boolean(isPro)}
        setChecked={() =>
          handleUpdateUser({
            id,
            photo,
            uid,
            name,
            email,
            links,
            isPro,
            active,
            linksCount,
          })
        }
      />,
      filters: [
        {
          text: 'True',
          value: 1,
        },
        {
          text: 'False',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.isPro === value,
    },
    {
      title: 'Member Since',
      render: ({ createdAt }) => <span style={{ whiteSpace: 'nowrap' }}>{moment(createdAt).format("DD MMM, YYYY")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: 'Active',
      render: ({ uid, isPro, id, photo, name, email, links, active, linksCount }) => <SwitchInput
        name={`user_active_${id}`}
        checked={Boolean(active)}
        setChecked={() =>
          handleUpdateUser(
            {
              id,
              photo,
              uid,
              name,
              email,
              links,
              isPro,
              active,
              linksCount,
            },
            true
          )
        }
      />,
      filters: [
        {
          text: 'active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.active === value,
    }
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setSearchParams({
      page: pagination.current,
      size: pagination.pageSize
    });
    dispatch(getUsers({
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize
    }));
  };

  return (
    <>
      <Table
        className="mt-3 p-3 overflow-auto"
        columns={columns}
        dataSource={data}
        pagination={{
          defaultPageSize: Number(searchParams.get('size')) || 100,
          defaultCurrent: Number(searchParams.get('page')) || 1,
          total: data? data[0]?.total : 10
        }}
        onChange={onChange}
      />

    </>
  );
};

export default UsersTable;
