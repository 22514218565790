import { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import cls from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./users.module.css";
import SearchPage from "components/search-page";
import LabelInput from "components/label-input";
import { colors } from "styles/theme";
import CardButtons from "components/card-buttons";
import CheckboxInput from "components/checkbox-input";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { validateEmail } from "utils";
import { message } from "antd";
import { API, PATH } from "services/axios";

const AddUser = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();;
  const isVendor = location.pathname === "/vendors/add";

  const [password, setPassword] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: ""
  });
  const [phone, setPhone] = useState('');

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    // console.log(location.pathname);
    console.log({ ...formData, phone: `+${phone}`, password: password });

    const validate = formData.firstname &&
      formData.lastname &&
      phone &&
      password;
    if (validate) {

      if (location.pathname === "/vendors/add") {
        // console.log('add vendors');
        try {
          const result = await API.post(PATH.addVendor, { ...formData, phone: `+${phone}`, password: password });
          console.log(result);
          if (result) {
            console.log('add vendor', result);
            message.success('Added vendor Successfully')
            navigate(-1);
          }
        } catch (err) {
          console.log(err);
        }

      }
      else if (location.pathname === "/users/add-edit") {
        // console.log('add  users');
        try {
          const result = await API.post(PATH.addUser, { ...formData, phone: `+${phone}`, password: password });
          console.log(result);
          if (result) {
            console.log('add user', result);
            message.success('Added user Successfully')
            navigate(-1);
          }
        } catch (err) {
          console.log(err);
        }

      }
      else {
        console.log('add vendors employee', params.id);
        try {
          const result = await API.post(PATH.addVendorEmployee(params.id), { ...formData, phone: `+${phone}`, password: password });
          console.log(result);
          if (result) {
            console.log('add user', result);
            navigate(-1);
          }
        } catch (err) {
          console.log(err);
        }

      }

    } else {
      message.error('Please fill all the fields')
    }

  }

  return (
    <div className={styles.wrapper}>
      <SearchPage title={`Add ${isVendor ? "Vendor" : "User"}`} isGoBack />

      <Card className={styles.addUserCard}>
        <p className={styles.title}>User Information</p>

        <Row>
          <Col md={6}>
            <LabelInput
              labelColor={colors.tableText}
              label="First Name"
              placeholder="Enter first name"
              labelFontSize="14px"
              inputHeight="37px"
              name='firstname'
              value={formData.firstname}
              onChange={onChange}
            />
          </Col>
          <Col md={6}>
            <LabelInput
              labelColor={colors.tableText}
              label="Last Name"
              placeholder="Enter last name"
              labelFontSize="14px"
              inputHeight="37px"
              name='lastname'
              value={formData.lastname}
              onChange={onChange}
            />
          </Col>
          <Col md={6}>
            <LabelInput
              labelColor={colors.tableText}
              label="Email"
              placeholder="Enter email"
              labelFontSize="14px"
              inputHeight="37px"
              name='email'
              value={formData.email}
              onChange={onChange}
            />
          </Col>
          <Col md={6}>
            <div className='mt-3'>
              <p
                className={styles.label}
                style={{ color: colors.tableText, fontSize: "14px" }}
              >
                Phone Number
              </p>
              <div className={styles.inputCover} style={{ height: "37px" }}>
                <PhoneInput
                  country={'us'}
                  inputStyle={{ width: '100%' }}
                  value={phone}
                  onChange={phone => setPhone(phone)}
                />

              </div>

            </div>
          </Col>

          <Col md={6}>

            <LabelInput
              labelColor={colors.tableText}
              label="Password"
              placeholder="Enter password"
              labelFontSize="14px"
              inputHeight="37px"
              isEye
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              showError={password.length < 8}
              errorMessage="Password must have at least 8 characters"
            />

          </Col>
        </Row>

        <CardButtons
          handleSave={handleSave}
          handleCancel={handleCancel}
          alignSelf="start"
        />
      </Card>
    </div>
  );
};

export default AddUser;
