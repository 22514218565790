import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import cls from "classnames";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { colors } from "styles/theme";
import SearchPage from "components/search-page";
import styles from "./connection-group-details.module.css";
import ModalEmpty from "components/modal-empty";
import CreateEditGroupCard from "components/create-edit-group-card";
import ConfirmCard from "components/confirm-card";
import {
  getConnectionGroupDetails,
  deleteUsersFromConnectionGroup,
  deleteConnectionGroup,
  updateConnectionGroup,
  getConnectionGroups,
  addConnectionGroup,
} from "redux/slices/connectionGroup/connectionGroup.async";
import CardSearchBar from "components/card-searchbar";
import { connectionUpdateUsersInDetails } from "redux/slices/connectionGroup/connection.slice";
import notify from "services/toastify";
import ConnectionsTable from "components/admin-connections-table/ConnectionsTable";
import LabelInput from "components/label-input";
import CardButtons from "components/card-buttons";

const ConnectionGroupDetails = () => {
  const { details, loading:detailsLoading } = useSelector(
    (state) => state.connectionGroups,
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isGroupOpen, setIsGroupOpen] = useState(false);

  useEffect(() => {
    if (id) {
        dispatch(getConnectionGroupDetails({ id }));
        setName(details?.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleRemoveUsers = () => {
    console.log('remove');
    setIsLoading(true);
    dispatch(deleteUsersFromConnectionGroup({ id, users: selected }))
      .then((res) => {
        console.log("Res", res);
        setIsLoading(false);
        const { status, message } = res.payload;
        if (status) {
          dispatch(connectionUpdateUsersInDetails(selected));
          setSelected([]);
        } else {
          notify(message, 0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Err", err);
        notify("Something went wrong!!!", 0);
      });
  };

  const handleDeleteGroup = () => {
    console.log('delete');
    setIsLoading(true);
    dispatch(deleteConnectionGroup({ id }))
      .then((res) => {
        console.log("Res", res);
        setIsLoading(false);
        const { status, message } = res.payload;
        if (status) {
          setIsConfirmOpen(false);
          navigate(-1);
        } else {
          notify(message, 0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Err", err);
        notify("Something went wrong!!!", 0);
      });
  };

  const handleSave = () => {
    setLoading(true);
    if (id) {
      dispatch(updateConnectionGroup({ id, name }))
        .then((res) => {
          console.log("Res", res);
          setLoading(false);
          const { status, message } = res.payload;
          if (status) {
            // notify(message);
            dispatch(getConnectionGroups());
            setIsGroupOpen(false);
          } else {
            notify(message, 0);
          }
        })
        .catch((err) => {
          console.log("Err", err);
          setLoading(false);
          notify("Something went wrong!!!");
        });
    } else {
      dispatch(addConnectionGroup({ name }))
        .then((res) => {
          console.log("Res", res);
          setLoading(false);
          const { status, message } = res.payload;
          if (status) {
            // notify(message);
            dispatch(getConnectionGroups());
            setIsGroupOpen(false);
          } else {
            notify(message, 0);
          }
        })
        .catch((err) => {
          console.log("Err", err);
          setLoading(false);
          notify("Something went wrong!!!");
        });
    }
  };



  return (
    <div className={styles.wrapper}>
      <ModalEmpty
        paddingTop="25vh"
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      >
        <ConfirmCard
          handleSave={handleDeleteGroup}
          setIsOpen={setIsConfirmOpen}
        />
      </ModalEmpty>

      <ModalEmpty
        paddingTop="25vh"
        isOpen={isGroupOpen}
        setIsOpen={setIsGroupOpen}
      >
        <Card className={styles.editCard}>
          <p className={styles.editTitle}>{details?.name}</p>
          <LabelInput
            classes="mt-4"
            labelColor={colors.tableText}
            label="Group name"
            placeholder="Group name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <CardButtons
            handleCancel={() => setIsGroupOpen(false)}
            handleSave={handleSave}
            loading={loading}
          />
        </Card>
      </ModalEmpty>

      <SearchPage
        title={details?.name} 
        isGoBack
        isSearchBar={false}>
        <div className="d-flex align-items-center">
          {selected.length > 0 && (
            <button
              disabled={isLoading}
              onClick={handleRemoveUsers}
              className={styles.removeBtn}
            >
              Remove from group
            </button>
          )}
          <button
            onClick={() => setIsGroupOpen(true)}
            className={cls(styles.btn, styles.edit)}
          >
            Edit
          </button>
          <button
            onClick={() => setIsConfirmOpen(true)}
            className={cls(styles.btn, styles.delete)}
          >
            Delete
          </button>
        </div>
      </SearchPage>
      
      <Card className={styles.card}>
        <CardSearchBar />
        <ConnectionsTable
          dataSource={details?.users}
          selected={selected}
          setSelected={setSelected}
          connections={details?.users}
        />
      </Card>
    </div>
  );
};

export default ConnectionGroupDetails;