import { combineReducers } from 'redux';
import admin from '../slices/admin/admin.slice';
import auth from '../slices/auth/auth.slice';
import campaigns from '../slices/campaigns/campaigns.slice';
import connections from '../slices/connections/connections.slice'
import dashboard from '../slices/dashboard/dashboard.slice';
import groups from '../slices/groups/groups.slice';
import connectionGroups from '../slices/connectionGroup/connection.slice'
import sales from '../slices/sales/sales.slice';
import users from '../slices/users/users.slice';

// combine different reducer function into one
export default combineReducers({
  admin,
  auth,
  campaigns,
  connections,
  dashboard,
  groups,
  connectionGroups,
  sales,
  users
});
