import { createSlice } from "@reduxjs/toolkit";
import { getConnectionGroupDetails, getConnectionGroups } from "./connectionGroup.async";

const initialState = {
  loading: false,
  data: [],
  details: null,
};

const connectionGroupsSlice = createSlice({
  name: "connectionGroups",
  initialState,
  reducers: {
    connectionUpdateUsersInDetails: (state, action) => {
      const newUsers = [];
      if (Array.isArray(state.details?.users)) {
        for (const item of state.details.users) {
          const isExist = action.payload.includes(item.id);
          if (!isExist) {
            newUsers.push(item);
          }
        }
      }
      state.details = { ...state.details, users: newUsers };
    },
  },
  extraReducers: {
    [getConnectionGroups.pending]: (state) => {
      state.loading = true;
    },
    [getConnectionGroups.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    [getConnectionGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
    },
    // Each group details
    [getConnectionGroupDetails.pending]: (state) => {
      state.loading = true;
    },
    [getConnectionGroupDetails.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    [getConnectionGroupDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.details = action.payload?.data;
    },
  },
});

export const { connectionUpdateUsersInDetails } = connectionGroupsSlice.actions;
export default connectionGroupsSlice.reducer;
