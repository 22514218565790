import React, { useEffect, useState } from 'react';
import { Card } from "reactstrap";
import cls from "classnames";
import styles from './vendorProfile.module.css'
import { API, PATH } from 'services/axios';
import EditDetails from './EditDetails';
import ModalEmpty from "components/modal-empty";

const VendorProfile = () => {
    const [data, setData] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const id = '139';


    const getVendorDetails = async () => {
        try {
            const result = await API.get(PATH.getVendorDetails(id));
            console.log(result);
            if (result) {
                // console.log(result);
                setData(result.data[0]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getVendorDetails();
    }, []);


    const handleEdit = () => {
        setIsOpen(true);
    }

    const handleCancel = () => {
        getVendorDetails();
        setIsOpen(false);
    }

    return (
        <div className={styles.wrapper}>
            <ModalEmpty isOpen={isOpen} setIsOpen={setIsOpen}>
                <EditDetails
                    id={id}
                    handleCancel={handleCancel}
                />
            </ModalEmpty>

            <Card className={cls(styles.card, "my-4")}>
                <div className={styles.detailDiv}>
                    <p className={styles.label}>Name</p>
                    <p className={styles.text}>{data?.firstname} {data?.lastname}</p>
                </div>
                <div className={styles.detailDiv}>
                    <p className={styles.label}>Address</p>
                    <p className={styles.text}>{data?.address || 'No data'}</p>
                </div>
                <div className={styles.detailDiv}>
                    <p className={styles.label}>Phone</p>
                    <p className={styles.text}>{data?.phone}</p>
                </div>
                <div className={styles.detailDiv}>
                    <p className={styles.label}>Email ID</p>
                    <p className={styles.text}>{data?.email}</p>
                </div>

                <button onClick={handleEdit} className={styles.editBtn}>
                    Edit
                </button>
            </Card>
        </div>
    );
};

export default VendorProfile;