import { createSlice } from '@reduxjs/toolkit';
import { getDashboard } from './dashboard.async';

const initialState = {
  loading: false,
  data: {}
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getDashboard.pending]: state => {
      state.loading = true;
    },
    [getDashboard.rejected]: state => {
      state.loading = false;
      state.error = true;
    },
    [getDashboard.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
    }
  }
});

export const {} = dashboardSlice.actions;
export default dashboardSlice.reducer;
