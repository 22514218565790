import { createSlice } from "@reduxjs/toolkit";
import { getGroupDetails, getGroups } from "./groups.async";

const initialState = {
  loading: false,
  data: [],
  details: null,
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    updateUsersInDetails: (state, action) => {
      const newUsers = [];
      if (Array.isArray(state.details?.users)) {
        for (const item of state.details.users) {
          const isExist = action.payload.includes(item.uid);
          if (!isExist) {
            newUsers.push(item);
          }
        }
      }
      state.details = { ...state.details, users: newUsers };
    },
  },
  extraReducers: {
    [getGroups.pending]: (state) => {
      state.loading = true;
    },
    [getGroups.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    [getGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
    },
    // Each group details
    [getGroupDetails.pending]: (state) => {
      state.loading = true;
    },
    [getGroupDetails.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    [getGroupDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.details = action.payload?.data;
    },
  },
});

export const { updateUsersInDetails } = groupsSlice.actions;
export default groupsSlice.reducer;
