import { createSlice } from "@reduxjs/toolkit";
import { getUsers, updateUserActive, updateUserIsPro } from "./users.aync";

const initialState = {
  loading: false,
  data: [],
  isSuccess: false,
  isError: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUsers(state) {
      state.loading = false;
      state.data = [];
    },
    updateUsers: (state, action) => {
      state.data = action.payload;
    },
    resetCall(state, action) {
      state.loading = false;
      state.isError = false;
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.loading = true;
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      if (Array.isArray(action.payload?.data)) {
        state.data = [...action.payload.data];
        console.log(action.payload.data);
      }
    },
    [updateUserIsPro.rejected]: (state, action) => {
      state.isError = "Something went wrong";
    },
    [updateUserIsPro.fulfilled]: (state, action) => {
      if (action.payload?.status) {
        state.isSuccess = action.payload.message;
      } else {
        state.isError = action.payload.message;
      }
    },
    [updateUserActive.rejected]: (state, action) => {
      state.isError = "Something went wrong";
    },
    [updateUserActive.fulfilled]: (state, action) => {
      if (action.payload?.status) {
        state.isSuccess = action.payload.message;
      } else {
        state.isError = action.payload.message;
      }
    },
  },
});

export const { resetUsers, updateUsers, resetCall } = usersSlice.actions;
export default usersSlice.reducer;
