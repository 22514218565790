import instagram from "../assets/icons/social/src_assets_instagram.png";
import snapchat from "../assets/icons/social/src_assets_snapchat.png";
import tiktok from "../assets/icons/social/src_assets_tiktok.png";
import facebook from "../assets/icons/social/src_assets_facebook.png";
import linkedIn from "../assets/icons/social/src_assets_linkedin.png";
import twitter from "../assets/icons/social/src_assets_twitter.png";
import youtube from "../assets/icons/social/src_assets_youtube.png";
import twitch from "../assets/icons/social/src_assets_twitch.png";
import pinterest from "../assets/icons/social/src_assets_pinterest.png";
import text from "../assets/icons/social/src_assets_number.png";
import email from "../assets/icons/social/src_assets_email.png";
import whatsapp from "../assets/icons/social/src_assets_whatsapp.png";
import address from "../assets/icons/social/src_assets_address.png";
import facetime from "../assets/icons/social/src_assets_facetime.png";
import call from "../assets/icons/social/src_assets_call.png";
import spotify from "../assets/icons/social/src_assets_spotify.png";
import appleMusic from "../assets/icons/social/src_assets_apple.png";
import soundCloud from "../assets/icons/social/src_assets_soundcloud.png";
import cashApp from "../assets/icons/social/src_assets_cashapp.png";
import venmo from "../assets/icons/social/src_assets_venmo.png";
import paypal from "../assets/icons/social/src_assets_paypal.png";
import website from "../assets/icons/social/src_assets_safari.png";
import customLink from "../assets/icons/social/src_assets_customlink.png";
import file from "../assets/icons/social/src_assets_file.png";
import linktree from "../assets/icons/social/linktree.png";
import calendly from "../assets/icons/social/calendly.png";
import podcasts from "../assets/icons/social/src_assets_podcasts.png";
import onlyFans from "../assets/icons/social/src_assets_onlyfans.png";
import clubhouse from "../assets/icons/social/src_assets_clubhouse.png";
import yelp from "../assets/icons/social/yelp.png";
import contact from "../assets/icons/social/src_assets_contactcard.png";
import etsy from "../assets/icons/social/etsy.png";
import appLink from "../assets/icons/social/applink.png";
import reviews from "../assets/icons/social/reviews.png";
import square from "../assets/icons/social/square.png";
import hoobe from "../assets/icons/social/hoo.be.png";
import mediakits from "../assets/icons/social/mediakits.png";
import opensea from "../assets/icons/social/opensea.png";
import logo from "../assets/icons/src_assets_popllogoapp.png";
import uberEats from "../assets/icons/social/uber-eats.png";
import swiggy from "../assets/icons/social/swiggy.png";
import zomato from "../assets/icons/social/zomato.png";
import skipDish from "../assets/icons/social/skip-dish.png";
import interac from "../assets/icons/social/interac.png";
import upi from "../assets/icons/social/upi.png";

import instagram_w from "../assets/icons/social/instagram_w.png";
import snapchat_w from "../assets/icons/social/snapchat_w.png";
import tiktok_w from "../assets/icons/social/tiktok_w.png";
import facebook_w from "../assets/icons/social/facebook_w.png";
import linkedIn_w from "../assets/icons/social/linkedin_w.png";
import twitter_w from "../assets/icons/social/twitter_w.png";
import youtube_w from "../assets/icons/social/youtube_w.png";
import twitch_w from "../assets/icons/social/twitch_w.png";
import pinterest_w from "../assets/icons/social/pinterest_w.png";
import text_w from "../assets/icons/social/text_w.png";
import email_w from "../assets/icons/social/email_w.png";
import whatsapp_w from "../assets/icons/social/whatsapp_w.png";
import address_w from "../assets/icons/social/address_w.png";
import facetime_w from "../assets/icons/social/facetime_w.png";
import call_w from "../assets/icons/social/call_w.png";
import spotify_w from "../assets/icons/social/spotify_w.png";
import appleMusic_w from "../assets/icons/social/applemusic_w.png";
import soundCloud_w from "../assets/icons/social/soundcloud_w.png";
import cashApp_w from "../assets/icons/social/cashapp_w.png";
import venmo_w from "../assets/icons/social/venmo_w.png";
import paypal_w from "../assets/icons/social/paypal_w.png";
import website_w from "../assets/icons/social/website_w.png";
import customLink_w from "../assets/icons/social/customlink_w.png";
import file_w from "../assets/icons/social/src_assets_file.png";
import linktree_w from "../assets/icons/social/linktree_w.png";
import calendly_w from "../assets/icons/social/calendly_w.png";
import podcasts_w from "../assets/icons/social/podcasts_w.png";
import onlyFans_w from "../assets/icons/social/onlyfans_w.png";
import clubhouse_w from "../assets/icons/social/clubhouse_w.png";
import yelp_w from "../assets/icons/social/yelp_w.png";
import contact_w from "../assets/icons/social/contact_w.png";
import uberEats_w from "../assets/icons/social/uber-eats.png";
import swiggy_w from "../assets/icons/social/swiggy.png";
import zomato_w from "../assets/icons/social/zomato.png";
import skipDish_w from "../assets/icons/social/skip-dish.png";
import interac_w from "../assets/icons/social/interac.png";
import upi_w from "../assets/icons/social/upi.png";

export const urls = [
  {
    id: 1,
    name: "Instagram",
    url: "https://www.instagram.com/",
    icon: instagram,
    icon_w: instagram_w,
  },
  {
    id: 2,
    name: "WhatsApp",
    url: "https://api.whatsapp.com/send?phone=",
    icon: whatsapp,
    icon_w: whatsapp_w,
  },
  {
    id: 3,
    name: "Twitter",
    url: "https://twitter.com/",
    icon: twitter,
    icon_w: twitter_w,
  },
  {
    id: 4,
    name: "Facebook",
    url: "https://www.facebook.com/",
    icon: facebook,
    icon_w: facebook_w,
  },
  {
    id: 5,
    name: "Snapchat",
    url: "https://www.snapchat.com/add/",
    icon: snapchat,
    icon_w: snapchat_w,
  },
  {
    id: 6,
    name: "Tiktok",
    url: "https://www.tiktok.com/@",
    icon: tiktok,
    icon_w: tiktok_w,
  },
  {
    id: 7,
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/",
    icon: linkedIn,
    icon_w: linkedIn_w,
  },
  {
    id: 8,
    name: "YouTube",
    url: "https://www.youtube.com/c/",
    icon: youtube,
    icon_w: youtube_w,
  },
  {
    id: 9,
    name: "Twitch",
    url: "https://www.twitch.tv/",
    icon: twitch,
    icon_w: twitch_w,
  },
  {
    id: 10,
    name: "Pinterest",
    url: "https://in.pinterest.com/",
    icon: pinterest,
    icon_w: pinterest_w,
  },
  {
    id: 11,
    name: "Spotify",
    url: null,
    icon: spotify,
    icon_w: spotify_w,
  },
  {
    id: 12,
    name: "Email",
    url: "mailto:",
    icon: email,
    icon_w: email_w,
  },
  {
    id: 13,
    name: "Address",
    url: "https://www.google.com/maps/search/?api=1&query=",
    icon: address,
    icon_w: address_w,
  },
  {
    id: 14,
    name: "Apple Music",
    url: null,
    icon: appleMusic,
    icon_w: appleMusic_w,
  },
  {
    id: 15,
    name: "SoundCloud",
    url: "https://soundcloud.com/",
    icon: soundCloud,
    icon_w: soundCloud_w,
  },
  {
    id: 16,
    name: "Cash App",
    url: "https://cash.app/",
    icon: cashApp,
    icon_w: cashApp_w,
  },
  {
    id: 17,
    name: "Podcasts",
    url: null,
    icon: podcasts,
    icon_w: podcasts_w,
  },
  {
    id: 18,
    name: "Website",
    url: null,
    icon: website,
    icon_w: website_w,
  },
  {
    id: 19,
    name: "Venmo",
    url: null,
    icon: venmo,
    icon_w: venmo_w,
  },
  {
    id: 20,
    name: "PayPal",
    url: "https://www.paypal.com/paypalme/",
    icon: paypal,
    icon_w: paypal_w,
  },
  {
    id: 21,
    name: "Linktree",
    url: null,
    icon: linktree,
    icon_w: linktree_w,
  },
  {
    id: 22,
    name: "Calendly",
    url: "https://calendly.com/event_types/user/",
    icon: calendly,
    icon_w: calendly_w,
  },
  {
    id: 23,
    name: "OnlyFans",
    url: null,
    icon: onlyFans,
    icon_w: onlyFans_w,
  },
  {
    id: 24,
    name: "Clubhouse",
    url: "https://clubhouse.com/@",
    icon: clubhouse,
    icon_w: clubhouse_w,
  },
  {
    id: 25,
    name: "Yelp",
    url: null,
    icon: yelp,
    icon_w: yelp_w,
  },
  {
    id: 26,
    name: "Call",
    url: "tel:",
    icon: call,
    icon_w: call_w,
  },
  {
    id: 27,
    name: "Message",
    url: "sms:",
    icon: text,
    icon_w: text_w,
  },
  {
    id: 28,
    name: "Contact",
    url: null,
    icon: call,
    icon_w: call_w,
  },
  {
    id: 29,
    name: "Text",
    url: "sms:",
    icon: text,
    icon_w: text_w,
  },
  {
    id: 30,
    name: "FaceTime",
    url: null,
    icon: facetime,
    icon_w: facetime_w,
  },
  {
    id: 31,
    name: "Custom link",
    url: null,
    icon: customLink,
    icon_w: customLink_w,
  },
  {
    id: 32,
    name: "File",
    url: null,
    icon: file,
    icon_w: file_w,
  },
  {
    id: 33,
    name: "Contact card",
    url: null,
    icon: contact,
    icon_w: contact_w,
  },
  {
    id: 34,
    name: "Embedded Video",
    url: "https://www.youtube.com/c/",
    icon: youtube,
    icon_w: youtube_w,
    placeholder: "Youtube user/channel link",
  },
  {
    id: 35,
    name: "Etsy",
    url: null,
    icon: etsy,
    icon_w: etsy,
    placeholder: "Etsy username",
    group: "Business",
  },
  {
    id: 36,
    name: "App Link",
    url: null,
    icon: appLink,
    icon_w: appLink,
    placeholder: "App link",
    group: "Business",
  },
  {
    id: 37,
    name: "Reviews",
    url: null,
    icon: reviews,
    icon_w: reviews,
    placeholder: "Reviews",
    group: "Business",
  },
  {
    id: 38,
    name: "Square",
    url: null,
    icon: square,
    icon_w: square,
    placeholder: "Square",
    group: "Business",
  },
  {
    id: 39,
    name: "Netwotap Pay",
    url: null,
    icon: logo,
    icon_w: logo,
    placeholder: "Netwotap Pay username",
    group: "Payments",
  },
  {
    id: 40,
    name: "hoo.be",
    url: null,
    icon: hoobe,
    icon_w: hoobe,
    placeholder: "hoo.be username",
    group: "More",
  },
  {
    id: 41,
    name: "OpenSea",
    url: null,
    icon: opensea,
    icon_w: opensea,
    placeholder: "OpenSea username",
    group: "More",
  },
  {
    id: 42,
    name: "Media Kits",
    url: null,
    icon: mediakits,
    icon_w: mediakits,
    placeholder: "Media Kits username",
    group: "More",
  },
  {
    id: 43,
    name: "Interac",
    url: null,
    icon: interac,
    icon_w: interac_w,
    placeholder: "Interac account",
    group: "Payments",
  },
  {
    id: 44,
    name: "UPI",
    url: null,
    icon: upi,
    icon_w: upi_w,
    placeholder: "UPI",
    group: "Payments",
  },
  {
    id: 45,
    name: "Uber Eats",
    url: null,
    icon: uberEats,
    icon_w: uberEats_w,
    placeholder: "Uber Eats",
    group: "Food",
  },
  {
    id: 46,
    name: "Zomato",
    url: null,
    icon: zomato,
    icon_w: zomato_w,
    placeholder: "Zomato",
    group: "Food",
  },
  {
    id: 47,
    name: "Swiggy",
    url: null,
    icon: swiggy,
    icon_w: swiggy_w,
    placeholder: "Swiggy",
    group: "Food",
  },
  {
    id: 48,
    name: "Skip the dish",
    url: null,
    icon: skipDish,
    icon_w: skipDish_w,
    placeholder: "Skip the dish",
    group: "Food",
  },
];
