import React, { useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "reactstrap";
import CardSearchBar from "components/card-searchbar";
import styles from "./user-connections.module.css";
import ConnectionsTable from "components/connections-table";
import { trimString } from "utils";

const UserConnections = ({ connections }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState("");

  // START LOCAL SEARCH
  const searchForData = (arr, s) => {
    s = trimString(s);
    return arr.filter((item, index) => {
      return (
        (item.name.toLowerCase() && item.name.toLowerCase().includes(s)) ||
        (item.email.toLowerCase() && item.email.toLowerCase().includes(s))
      );
    });
  };

  const searchData = useMemo(() => {
    return search.length > 0
      ? searchForData(connections, search.toLowerCase())
      : connections;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, connections?.length]);
  // END LOCAL SEARCH

  const navToAdd = () => {
    navigate(`/connections/add-edit`);
  };

  return (
    <Card className={styles.card}>
      <CardSearchBar
        title="Connections"
        // rightBtnText="Add Connection"
        // handleRightBtn={navToAdd}
        search={search}
        setSearch={setSearch}
      />

      <ConnectionsTable data={searchData} classes="mt-3" />
    </Card>
  );
};

export default UserConnections;
