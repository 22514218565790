import React, { useState } from "react";
import styles from "./profile-edit.module.css";
import IconInput from "components/icon-input";

import emailImg from "assets/icons/email-grey.svg";
import locationImg from "assets/icons/location-grey.svg";
import usersImg from "assets/icons/users-grey.svg";
import { Card } from "reactstrap";

const ProfileEdit = ({ user ,setIsOpen}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [email, setEmail] = useState(user?.email);
  const [location, setLocation] = useState(user?.location);
  const [accName, setAccName] = useState("Business (Pro)");

  return (
    <Card className={styles.card}>
      <div className={styles.userInfoCover}>
        {user?.photo && user?.photo !== 'null' ? <img src={user?.photo} className={styles.picCover} alt="" /> : <div className={styles.picCover}></div>}
        <p className={styles.nameText}>{user?.name}</p>
        <p className={styles.jobText}>{user?.role}</p>
      </div>
      <div>
        <p className={styles.accountText}>Account Details</p>
        <IconInput
          label="Email"
          style={{ width: '100%' }}
          icon={emailImg}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          readOnly={!isEdit}
        />
      </div>
      <button onClick={() => setIsOpen(true)} className={styles.editBtn}>
        Change Password
      </button>
    </Card>
  );
};

export default ProfileEdit;
