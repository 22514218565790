import SearchPage from "components/search-page";
import UserConnections from "components/user-connections";
import UserLinks from "components/user-links";
import UserLinksCard from "components/user-links-card";
import UserOrders from "components/user-orders";
import UserPreviewCard from "components/user-preview-card";
import UserProductsCard from "components/user-products-card";
import UserProfileCard from "components/user-profile-card";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Card, Col, Row, Spinner } from "reactstrap";
import { getUserDetails } from "redux/slices/users/users.aync";
import styles from "./user-details.module.css";

const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setLoading(true);
    dispatch(getUserDetails({ uid: id })).then(({ payload }) => {
      setUser(payload.data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  }, []);


  if(loading) {
    return <div className={styles.spinnerContainer}><Spinner variant="secondary" /></div>;
  }


  return (
    <div className={styles.wrapper}>
      <SearchPage title="User Details" isGoBack isSearchBar={false}>
        {/* <button className={styles.editBtn}>Edit</button> */}
      </SearchPage>

      <Row className="row-equal">
        <Col md={8}>
          <div>
            <Card className={styles.card}>
              <p className={styles.detailTitle}>User Details</p>
              <div className="mt-4">
                <p className={styles.detailLabel}>Name</p>
                <p
                  className={styles.detailText}
                >{`${user?.firstname} ${user.lastname}`}</p>
              </div>
              <div className="mt-3">
                <p className={styles.detailLabel}>Description</p>
                <p className={styles.detailText}>{user?.description}</p>
              </div>
              <div className="mt-3">
                <p className={styles.detailLabel}>Location</p>
                <p className={styles.detailText}>{user?.location}</p>
              </div>
              <div className="mt-3">
                <p className={styles.detailLabel}>Pro User</p>
                <p className={styles.detailText}>
                  {user?.isPro ? "Yes" : "No"}
                </p>
              </div>
              <div className="mt-3">
                <p className={styles.detailLabel}>Username</p>
                <p className={styles.detailText}>
                  {user?.username || 'N/A'}
                </p>
              </div>
            </Card>
            <Row className="">
              <Col>
                <UserLinksCard linkTaps={user?.linkTaps} />
              </Col>
              <Col>
                <UserProfileCard analytics={user?.analytics} />
              </Col>
            </Row>
          </div>
          {/* Products */}
          <UserProductsCard categories={user?.categories} />
        </Col>
        <Col md={4}>
          <UserPreviewCard link={`https://netwotap.me/${user?.username || user?.link}`} />
          <iframe
            className={styles.iFrame}
            title="User Preview"
            src={`https://netwotap.me/${user?.username || user?.link}`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserOrders orders={user?.orders} />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserConnections connections={user?.connections} />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserLinks links={user?.links} />
        </Col>
      </Row>
    </div>
  );
};

export default UserDetails;
