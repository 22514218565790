import React, { useEffect } from "react";
import { Card } from "reactstrap";
import cls from "classnames";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import styles from "./vendor-details.module.css";
import SearchPage from "components/search-page";
import VendorUsers from "components/vendor-users";
import { API, PATH } from "services/axios";
import { useState } from "react";

const VendorDetails = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const navToEdit = () => {
    const path = location.pathname.split("/");
    path.pop();
    const newPath = path.join("/");
    navigate(`${newPath}/edit/${params.id}`);
  };

  const getVendorDetails = async () => {
    try {
      const result = await API.get(PATH.getVendorDetails(params.id));
      console.log(result);
      if (result) {
        // console.log(result);
        setData(result.data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVendorDetails();
  }, []);

  // console.log(data);

  return (
    <div className={styles.wrapper}>
      <SearchPage title="Vendor Details" />

      <Card className={cls(styles.card, "my-4")}>
        <div className={styles.detailDiv}>
          <p className={styles.label}>Name</p>
          <p className={styles.text}>{data?.firstname} {data?.lastname}</p>
        </div>
        <div className={styles.detailDiv}>
          <p className={styles.label}>Address</p>
          <p className={styles.text}>{data?.address || 'No data'}</p>
        </div>
        <div className={styles.detailDiv}>
          <p className={styles.label}>Phone</p>
          <p className={styles.text}>{data?.phone}</p>
        </div>
        <div className={styles.detailDiv}>
          <p className={styles.label}>Email ID</p>
          <p className={styles.text}>{data?.email}</p>
        </div>

        <button onClick={navToEdit} className={styles.editBtn}>
          Edit
        </button>
      </Card>

      <VendorUsers uid={data?.uid} />
    </div>
  );
};

export default VendorDetails;
