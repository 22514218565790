import React from 'react';
import { Outlet, Navigate } from "react-router-dom";
import HomeLayout from "layouts/Home";
import { getUserRole } from 'utils';

const AdminRoute = ({ user, children }) => {
    if (getUserRole() === 'Admin') {
        // console.log('admin route', getUserRole());
        return (
            <Outlet />
        );
    } else {
        return <Navigate replace to="/home" />;
    }
    // <>{children}</>
    // if (!getUserRole() === 'Admin') return <Navigate replace to="home" />;
};

export default AdminRoute;