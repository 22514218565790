import { createSlice } from "@reduxjs/toolkit";
import { getCampaigns } from "./campaigns.async";

const initialState = {
  loading: false,
  campaignsList: [],
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    resetCampaigns(state) {
      state.loading = false;
      state.campaignsList = [];
    },
  },
  extraReducers: {
    [getCampaigns.pending]: (state) => {
      state.loading = true;
    },
    [getCampaigns.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    [getCampaigns.fulfilled]: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload?.data)) {
        state.campaignsList = [...action.payload.data];
      }
    },
  },
});

export const { resetCampaigns } = campaignsSlice.actions;
export default campaignsSlice.reducer;
