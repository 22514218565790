import { Container, Row, Col } from "reactstrap";

const Common = ({ children }) => {
  return (
    <Container fluid>
      <Row>
        <Col className="p-0">{children}</Col>
      </Row>
    </Container>
  );
};

export default Common;
