import { useEffect, useState, useRef, useMemo } from "react";
import { Card, Col, Row } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styles from "./users.module.css";
import SearchPage from "components/search-page";
import UsersTable from "components/users-table";
import CountCard from "components/count-card";
import CardSearchBar from "components/card-searchbar";
import { getUsers } from "redux/slices/users/users.aync";
import { resetUsers } from "redux/slices/users/users.slice";
import { getGroups } from "redux/slices/groups/groups.async";
import ModalEmpty from "components/modal-empty";
import AddToGroupCard from "components/add-to-group-card";
import CreateEditGroupCard from "components/create-edit-group-card";

import usersImg from "assets/icons/users-circle.svg";
import plusImg from "assets/icons/plus-black.svg";
import plusWhiteImg from "assets/icons/plus-white.svg";
import notFoundImg from "assets/images/not-found-user.svg";
import { trimString } from "utils";

const limit = 100;

const Users = () => {
  const { data: usersData, loading } = useSelector(
    (state) => state.users,
    shallowEqual
  );

  const { data: groupsData, loading: groupLoading } = useSelector(
    (state) => state.groups,
    shallowEqual
  );
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  // const searchBarRef = useRef();


  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false); // Add users to group modal
  const [isGroupOpen, setIsGroupOpen] = useState(false); // Create group modal
  const [search, setSearch] = useState("");

  // START INFINITE SCROLL
  const offset = useRef(0);

  useEffect(() => {
    dispatch(getUsers({ offset: offset.current, limit }));
    dispatch(getGroups());

    return () => {
      dispatch(resetUsers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // END INFINITE SCROLL

  // START LOCAL SEARCH
  const searchForData = (arr, s) => {
    s = trimString(s);
    return arr.filter((item, index) => {
      return (
        (item.name.toLowerCase() && item.name.toLowerCase().includes(s)) ||
        (item.email.toLowerCase() && item.email.toLowerCase().includes(s))
      );
    });
  };

  const searchData = useMemo(() => {
    return search.length > 0
      ? searchForData(usersData, search.toLowerCase())
      : usersData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, usersData]);
  // END LOCAL SEARCH

  const navToGroupDetails = (id) => {
    navigate(`${location.pathname}/group/${id}`);
  };

  const navToAdd = () => {
    navigate(`${location.pathname}/add-edit`);
  };


  return (
    <div className={styles.wrapper}>
      <ModalEmpty paddingTop="25vh" isOpen={isOpen} setIsOpen={setIsOpen}>
        <AddToGroupCard
          setIsOpen={setIsOpen}
          options={groupsData}
          selected={selected}
        />
      </ModalEmpty>

      <ModalEmpty
        paddingTop="25vh"
        isOpen={isGroupOpen}
        setIsOpen={setIsGroupOpen}
      >
        <CreateEditGroupCard
          title="Create New Group"
          setIsOpen={setIsGroupOpen}
        />
      </ModalEmpty>

      <>
        <SearchPage title="Groups" isSearchBar={false} />

        <div className=" d-flex flex-wrap gap-3">
          {groupsData?.length > 0 &&
            groupsData.map((item) => (
              <div
                key={item.id}
                onClick={() => navToGroupDetails(item.id)}
                className="pointer"
              >
                <CountCard
                  photo={usersImg}
                  name={item.name}
                  count={item.users?.length}
                />
              </div>
            ))}
          <div>
            <Card
              onClick={() => setIsGroupOpen(true)}
              className={styles.addCard}
            >
              <img src={plusImg} alt="Add" />
              <p>Create New Group</p>
            </Card>
          </div>
        </div>

        <SearchPage title="Users List" isSearchBar={false} />

        <Card className={styles.card}>
          <CardSearchBar
            //ref={searchBarRef}
            search={search}
            setSearch={setSearch}
            isRightBtn={selected.length > 0}
            rightBtnText2="Add to group"
            handleRightBtn2={() => setIsOpen(true)}
            rightBtnText="Add User"
            handleRightBtn={navToAdd}
          />
          <UsersTable
            data={searchData}
            selected={selected}
            setSelected={setSelected}
          />
        </Card>

      </>
    </div>
  );
};

export default Users;
