import { Table } from 'antd';
import CheckboxInput from 'components/checkbox-input';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getConnections } from 'redux/slices/connections/connections.async';
import { toTitleCase } from "utils";
import styles from './connections-table.module.css'

const ConnectionsTable = ({
    classes = "",
    dataSource,
    connections = [],
    selected = [],
    setSelected,
}) => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const isExist = (id) => {
        const idx = selected.findIndex((x) => x === id);
        if (idx === -1) {
            return false;
        } else {
            return true;
        }
    };

    const handleSelect = (id) => {
        console.log('click');
        const arr = [...selected];
        const idx = arr.findIndex((x) => x === id);
        if (idx === -1) {
            arr.push(id);
        } else {
            arr.splice(idx, 1);
        }
        setSelected(arr);
    };


    const columns = [
        {
            title: ' ',
            render: ({ id }) => <CheckboxInput
                name={id}
                checked={isExist(id)}
                setChecked={() => handleSelect(id)}
            />
        },
        {
            title: 'Name',
            render: ({ name, photo }) => <div className="d-flex align-items-center">
                {photo && photo != "null" ? (
                    <img src={photo} alt={name} className={styles.avatar} />
                ) : (
                    <div className={styles.avatar} />
                )}
                <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>{name}</p>
            </div>,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Email ID',
            dataIndex: 'email',
        },
        {
            title: 'Phone',
            render: ({ phone }) => <span>{phone ? `${phone}` : '--'}</span>
        },
        {
            title: 'City',
            render: ({ city }) => <span>{city ? toTitleCase(city) : '--'}</span>
        }
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
        setSearchParams({
            page: pagination.current,
            size: pagination.pageSize
        });
        dispatch(getConnections({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize
        }));
    };

    return (
        <>
            <Table
                className="mt-3 p-3 overflow-auto"
                columns={columns}
                dataSource={dataSource}
                onChange={onChange}
                pagination={{
                    defaultPageSize: Number(searchParams.get('size')) || 10,
                    defaultCurrent: Number(searchParams.get('page')) || 1,
                    total: connections ? connections[0]?.total : 10
                }}
            />
        </>
    );
};

export default ConnectionsTable;