import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import styles from "./vendors.module.css";
import SwitchInput from "components/switch-input";
import CheckboxInput from "components/checkbox-input";
import SearchPage from "components/search-page";
import cls from "classnames";
import moreImg from "assets/icons/more.svg";
import plusImg from "assets/icons/plus-white.svg";
import ModalEmpty from "components/modal-empty";
import UserCreatedCard from "components/user-created-card";
import { Table } from "antd";
import { API, PATH } from "services/axios";
import moment from "moment";


const Vendors = () => {
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreatedModal, setIsCreatedModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const navToAdd = () => {
    navigate(`${location.pathname}/add`);
  };

  const navToDetails = (id) => {
    navigate(`${location.pathname}/${id}`);
  };

  const getVendors = async () => {
    try {
      const result = await API.get(PATH.getVendors);
      console.log(result);
      if (result) {
        setData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVendors();
    console.log(data);
  }, []);

  const columns = [
    {
      title: 'Name',
      render: (item) => <div
        onClick={() => navToDetails(item.id)}
        className="d-flex align-items-center pointer"
      >
        <div className={styles.avatar} />
        <span style={{ whiteSpace: 'nowrap' }}>{item.firstname} {item.lastname}</span>
      </div>,
      sorter: (a, b) => a.firstname.localeCompare(b.firstname)
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Employees',
      dataIndex: 'employees',
      sorter: (a, b) => a.employees - b.employees,
    },
    {
      title: 'Pro',
      render: ({ isPro }) => <div className={
        Boolean(isPro) ?
          (cls(styles.proDiv, { [styles.active]: true }))
          : (cls(styles.proDiv, { [styles.inactive]: true }))
      }>
        <span>{Boolean(isPro) ? 'True' : 'False'}</span>
      </div>,
      filters: [
        {
          text: 'True',
          value: 1,
        },
        {
          text: 'False',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.isPro === value,
    },
    {
      title: 'Created At',
      render: ({ createdAt }) => <span style={{ whiteSpace: 'nowrap' }}>{moment(createdAt).format("DD MMM, YYYY")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: 'Active',
      render: ({ id, active }) => <SwitchInput
        name={`user_active_${id}`}
        checked={Boolean(active)}
      />,
      filters: [
        {
          text: 'active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.active === value,
    },
    // {
    //   title: ' ',
    //   render: () => <img src={moreImg} alt="more" />,
    // },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setSearchParams({
      page: pagination.current,
      size: pagination.pageSize
    });
  };


  return (
    <div className={styles.wrapper}>
      <ModalEmpty
        paddingTop="20vh"
        isOpen={isCreatedModal}
        setIsOpen={setIsCreatedModal}
      >
        <UserCreatedCard />
      </ModalEmpty>

      <SearchPage title="Vendors List">
        <button onClick={navToAdd} className={styles.addBtn}>
          <img src={plusImg} alt="plus" /> Add Vendor
        </button>
      </SearchPage>

      <Card className="border-0">

        <Table
          className="mt-3 p-3 overflow-auto"
          columns={columns}
          dataSource={data}
          onChange={onChange}
          pagination={{
            defaultPageSize: Number(searchParams.get('size')) || 10,
            defaultCurrent: Number(searchParams.get('page')) || 1,
        }}
        />

      </Card>
    </div>
  );
};

export default Vendors;
