import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, PATH } from "services/axios";

// Get Groups
export const getGroups = createAsyncThunk("groups/getGroups", async () => {
  return await API.get(PATH.getGroups);
});
// Get Group Details
export const getGroupDetails = createAsyncThunk(
  "groups/getGroupDetails",
  async ({ id }) => {
    return await API.get(PATH.getGroupDetails(id));
  }
);

// Add Group
export const addGroup = createAsyncThunk(
  "groups/addGroup",
  async ({ name }) => {
    const data = { name };
    return await API.post(PATH.addGroup, data);
  }
);

// Update Group
export const updateGroup = createAsyncThunk(
  "groups/updateGroup",
  async ({ id, name }) => {
    const data = { name };
    return await API.patch(PATH.updateGroup(id), data);
  }
);

// Delete Group
export const deleteGroup = createAsyncThunk(
  "groups/deleteGroup",
  async ({ id }) => {
    return await API.delete(PATH.deleteGroup(id));
  }
);

// Add Users to Group
export const addUsersToGroup = createAsyncThunk(
  "groups/addUsersToGroup",
  async ({ id, users }) => {
    const data = { users };
    return await API.post(PATH.addUserToGroup(id), data);
  }
);

// Delete Users from Group
export const deleteUsersFromGroup = createAsyncThunk(
  "groups/deleteUsersFromGroup",
  async ({ id, users }) => {
    const data = { data: { users } };
    return await API.delete(PATH.deleteUserFromGroup(id), data);
  }
);
