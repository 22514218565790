import React, { useEffect } from 'react'

function RedirectTo() {

    useEffect(() => { 
        const query = new URLSearchParams(window.location.search);
        const ref = query.get('ref');
        if(ref) {
            window.location.href = ref;
        }
    }, []);

  return (
   <></>
  )
}

export default RedirectTo;