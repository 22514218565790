import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import LabelInput from "components/label-input";
import { Card } from "reactstrap";
import CardButtons from "components/card-buttons";

import styles from "./change-password-card.module.css";
import notify from "services/toastify";
import { changePassword } from "redux/slices/auth/auth.async";
import { resetCall } from "redux/slices/auth/auth.slice";
import { getUserRole } from "utils";
import auth from "firebase.auth";
import { signInWithEmailAndPassword, updatePassword } from "firebase/auth";

const ChangePasswordCard = ({ setIsOpen }) => {
  const { user, loading, isSuccess, isError } = useSelector(
    (state) => state.auth,
    shallowEqual
  );
  const dispatch = useDispatch();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(resetCall());
      notify(isError, 0);
    } else if (isSuccess) {
      dispatch(resetCall());
      notify(isSuccess);
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSave = () => {

    const conditions =
      // currentPassword.length >= 6 &&
      newPassword.length >= 6 &&
      confirmPassword.length >= 6;

    if (conditions) {
      if (newPassword !== confirmPassword) {
        notify("New password and Confirm password should be same", 0);
        return;
      }

      if (getUserRole() === 'Admin') {

        // for admin
        const data = { currentPassword, newPassword, confirmPassword };
        console.log(data);
        dispatch(changePassword(data));
      } else {

        // for vendor
        signInWithEmailAndPassword(auth, user.email, currentPassword)
          .then((userCredential) => {
            // Signed in 
            const vendorUser = userCredential.user;
            updatePassword(vendorUser, newPassword).then(() => {
              notify("Successfully Changed Password");
              setIsOpen(false);
            }).catch((error) => {
              console.log(error);
              notify("Change Password Failed", 0);
            });
          })
          .catch((error) => {
            const errorMessage = error.message;
            console.log(errorMessage);
            notify("Wrong Current password", 0);
          });

      }
    } else {
      notify("Minimum password length 6", 0);
    }
  };

  return (
    <Card className={styles.card}>
      <p className={styles.title}>Change Password</p>
      <LabelInput
        label="Current Password"
        isSecure
        placeholder="Enter Password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <LabelInput
        label="New Password"
        isSecure
        placeholder="Enter Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <LabelInput
        label="Confirm New Password"
        isSecure
        placeholder="Enter Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      <CardButtons handleCancel={handleCancel} handleSave={handleSave} />
    </Card>
  );
};

export default ChangePasswordCard;
