import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, PATH } from "services/axios";

// Get Campaigns
export const getCampaigns = createAsyncThunk(
  "campaigns/getCampaigns",
  async ({ offset, limit }) => {
    return await API.get(PATH.getCampaigns(offset, limit));
  }
);

// Send Notification
export const sendNotification = createAsyncThunk(
  "campaigns/sendNotification",
  async ({ users, title, body, image, name, link }) => {
    const data = { users, title, body, image, name, link };
    return await API.post(PATH.sendNotification, data);
  }
);

// Send Email
export const sendEmail = createAsyncThunk(
  "campaigns/sendEmail",
  async ({ subject, body, users, extraEmails }) => {
    const data = { subject, body, users, extraEmails };
    return await API.post(PATH.sendEmail, data);
  }
);
