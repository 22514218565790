import CardButtons from 'components/card-buttons';
import LabelInput from 'components/label-input';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'reactstrap';
import { addConnectionGroup, getConnectionGroupDetails, getConnectionGroups, updateConnectionGroup } from 'redux/slices/connectionGroup/connectionGroup.async';
import notify from 'services/toastify';
import { colors } from 'styles/theme';
import styles from './CreateEditConnectionGroupCard.module.css'

const CreateEditConnectionGroupCard = ({ title = "", id, name: oldName, setIsOpen }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(id ? oldName : "");
  
    const handleSave = () => {
      setLoading(true);
      if (id) {
        dispatch(updateConnectionGroup({ id, name }))
          .then((res) => {
            console.log("Res", res);
            dispatch(getConnectionGroupDetails({ id }));
            setLoading(false);
            const { status, message } = res.payload;
            if (status) {
              // notify(message);
              dispatch(getConnectionGroups());
              setIsOpen(false);
            } else {
              notify(message, 0);
            }
          })
          .catch((err) => {
            console.log("Err", err);
            setLoading(false);
            notify("Something went wrong!!!");
          });
      } else {
        dispatch(addConnectionGroup({ name }))
          .then((res) => {
            console.log("Res", res);
            setLoading(false);
            const { status, message } = res.payload;
            if (status) {
              // notify(message);
              dispatch(getConnectionGroups());
              setIsOpen(false);
            } else {
              notify(message, 0);
            }
          })
          .catch((err) => {
            console.log("Err", err);
            setLoading(false);
            notify("Something went wrong!!!");
          });
      }
    };
  
    return (
      <Card className={styles.card}>
        <p className={styles.title}>{title}</p>
        <LabelInput
          classes="mt-4"
          labelColor={colors.tableText}
          label="Group name"
          placeholder="Group name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <CardButtons
          handleCancel={() => setIsOpen(false)}
          handleSave={handleSave}
          loading={loading}
        />
      </Card>
    );
  };

export default CreateEditConnectionGroupCard;