import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import cls from "classnames";
import styles from "./orders-tabbar.module.css";

const OrdersTabBar = ({ activeTab, setActiveTab, handleIssue }) => {
  return (
    <Nav>
      <NavItem>
        <NavLink
          className={cls(styles.navLink, {
            [styles.navLinkActive]: activeTab === "upcoming",
          })}
          active={activeTab === "upcoming"}
          onClick={() => setActiveTab("upcoming")}
        >
          Upcoming Orders
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={cls(styles.navLink, {
            [styles.navLinkActive]: activeTab === "past",
          })}
          active={activeTab === "past"}
          onClick={() => setActiveTab("past")}
        >
          Past Orders
        </NavLink>
      </NavItem>
      {Boolean(handleIssue) && (
        <p onClick={handleIssue} className={styles.issueText}>
          Have an issue? <span>Report</span>
        </p>
      )}
    </Nav>
  );
};

export default OrdersTabBar;
