import { Card } from "reactstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./qr-links.module.css";
import SearchPage from "components/search-page";
import { CSVLink } from "react-csv"
import CheckboxInput from "components/checkbox-input";

// import moreImg from "assets/icons/more.svg";
import downloadImg from "assets/icons/download-primary.svg";
import { message, Table } from "antd";
import moment from "moment";
import { API, PATH } from "services/axios";
import { useEffect } from "react";
import { useState } from "react";


const QRLinks = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState([]);
  const [generateData, setGenerateData] = useState([]);

  const [value, setValue] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const navToAdd = () => {
    navigate(`${location.pathname}/add-edit`);
  };


  const getQRlinks = async () => {
    try {
      const result = await API.get(PATH.getQRlinks);
      console.log(result);
      if (result) {
        // console.log(result.data);
        setData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getQRlinks();
    // console.log(data);
  }, []);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: (a, b) => a.id- b.id
    },
    {
      title: 'Link',
      dataIndex: 'link',
    },
    {
      title: 'Date',
      render: ({ date }) => <span style={{ whiteSpace: 'nowrap' }}>{moment(date).format("DD MMM, YYYY")}</span>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: 'Used',
      render: ({ id, used }) => Boolean(used) ? 'Yes' : 'No'
    },
    {
      title: 'Active',
      render: ({ id, active }) => Boolean(active) ? 'Yes' : 'No',
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setSearchParams({
      page: pagination.current,
      size: pagination.pageSize
    });
  };


  const handleGenerate = async () => {
    if (parseInt(value) && parseInt(value) > 0) {
      console.log(parseInt(value));

      try {
        const result = await API.post(PATH.generateQRlinks(parseInt(value)));
        console.log(result);
        if (result) {
          console.log('generate', result.data);
          setGenerateData(result.data);
        }
      } catch (err) {
        console.log(err);
      }


    } else {
      message.error('Enter a number');
    }

  }

  return (
    <div className={styles.wrapper}>
      <SearchPage title="QR Links" onTitleClick={navToAdd} />

      <Card className="border-0 mt-4">
        <div className={styles.genCard}>
          <div className={styles.genInput}>
            <label htmlFor="genNo">
              How many QR links do you want to generate?
            </label>
            <input
              type="number"
              name="genNo"
              id="genNo"
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <button
            className={styles.genBtn}
            onClick={handleGenerate}
          >Generate</button>
        </div>
      </Card>

      {generateData.length > 0 && <Card className="border-0 mt-4">
        <div className={styles.cardHeaderAlign}>
          <p className={styles.cardTitle}>{generateData.length} Links generated successfully</p>
          <div className="d-flex align-items-center">
            <span className={styles.downloadText}>You can download from here</span>
            <button className={styles.downloadBtn}>
              <CSVLink
                filename={"New_QRlink_generate.csv"}
                data={generateData}
                onClick={() => {
                  message.success("The file is downloading")
                }}
              >
                Download
                <img src={downloadImg} alt="download" />
              </CSVLink>

            </button>
          </div>
        </div>

        <Table
          className="mt-3 p-3 overflow-auto"
          columns={columns}
          dataSource={generateData}
          onChange={onChange}
        />

      </Card>}

      <Card className="border-0 mt-4">
        <div className={styles.cardHeaderAlign}>
          <p className={styles.cardTitle}>Previous Links</p>
          <button className={styles.exportBtn}>
            <CSVLink
              filename={"Previous_QRlink_generate.csv"}
              data={data}
              onClick={() => {
                message.success("The file is downloading")
              }}
            >
              Export
            </CSVLink>
          </button>
        </div>

        <Table
          className="mt-3 p-3 overflow-auto"
          columns={columns}
          dataSource={data}
          onChange={onChange}
          pagination={{
            defaultPageSize: Number(searchParams.get('size')) || 10,
            defaultCurrent: Number(searchParams.get('page')) || 1,
          }}
        />
      </Card>
    </div>
  );
};

export default QRLinks;
