import { useState } from "react";
import styles from "./label-input.module.css";

import eyeOnImg from "assets/icons/eye-on.svg";
import eyeOffImg from "assets/icons/eye-off.svg";

const LabelInput = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  isRequired = true,
  isSecure = false,
  isEye = false,
  type = isEye || isSecure ? "password" : "text",
  classes = "mt-3",
  labelColor = "rgba(35, 49, 75, 0.65)",
  inputHeight = "48px",
  labelFontSize = "16px",
  errorMessage = "",
  showError = false,
  disabled,
  ...inputProps
}) => {
  const [rightMsg, setRightMsg] = useState("Show");
  const [rightIcon, setRightIcon] = useState(eyeOnImg);

  const toggleSecret = () => {
    var x = document.getElementById(label);
    if (x.type === "password") {
      x.type = "text";
      setRightMsg("Hide");
      setRightIcon(eyeOffImg);
    } else {
      x.type = "password";
      setRightMsg("Show");
      setRightIcon(eyeOnImg);
    }
  };

  return (
    <div className={classes}>
      <p
        className={styles.label}
        style={{ color: labelColor, fontSize: labelFontSize }}
      >
        {label}
        {isRequired && "*"}
      </p>
      <div className={styles.inputCover} style={{ height: inputHeight }}>
        <input
          className={styles.input}
          style={{
            width: isEye ? "92%" : isSecure ? "85%" : "100%",
            fontSize: labelFontSize,
          }}
          id={label}
          name={name}
          type={type}
          value={value}
          autocomplete="new-password"
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          {...inputProps}
        />
        {isEye && (
          <span onClick={toggleSecret}>
            <img src={rightIcon} alt="eye" />
          </span>
        )}
        {isSecure && (
          <span onClick={toggleSecret} className={styles.rightText}>
            {rightMsg}
          </span>
        )}
      </div>
      {showError && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default LabelInput;
