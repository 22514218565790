import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CountCard from "components/count-card";
import SearchPage from "components/search-page";
import SubscriptionsCard from "components/subscriptions-card";
import styles from "./home.module.css";

import usersImg from "assets/icons/users-circle.svg";
import ordersImg from "assets/icons/orders-circle.svg";
import productsImg from "assets/icons/products-circle.svg";
import connectionImg from "assets/icons/connection-circle.svg";
import { getDashboard } from "../../redux/slices/dashboard/dashboard.async";
import { getUserRole } from "utils";
import { API, PATH } from "services/axios";

const Home = () => {
  const {
    subscriptions,
    totalOrders,
    totalConnections,
    totalProducts,
    totalUsers,
    employees,
    connections,
    campaigns
  } = useSelector((state) => state.dashboard.data, shallowEqual);

  useEffect(() => {
    dispatch(getDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <SearchPage title="Dashboard" isSearchBar={false} />

      {getUserRole() === 'Admin' ? <Row className="gy-3">
        <Col>
          <CountCard photo={usersImg} name="Total Users" count={totalUsers} />
        </Col>
        <Col>
          <CountCard
            photo={ordersImg}
            name="Total Orders"
            count={totalOrders}
          />
        </Col>
        <Col>
          <CountCard
            photo={productsImg}
            name="Products"
            count={totalProducts}
          />
        </Col>
        <Col>
          <CountCard
            photo={connectionImg}
            name="Connections"
            count={totalConnections}
          />
        </Col>
      </Row>
        :
        <Row className="gy-3">
          <Col>
            <CountCard photo={connectionImg} name="Connections" count={connections} />
          </Col>
          <Col>
            <CountCard photo={ordersImg} name="Employees" count={employees} />
          </Col>
          <Col>
            <CountCard photo={usersImg} name="Campaigns" count={campaigns} />
          </Col>
        </Row>}

      <Row>
        <Col>
          {
            getUserRole() === 'Admin' ?
              <SubscriptionsCard subscriptions={subscriptions} />
              : <></>
          }

        </Col>
      </Row>
    </div>
  );
};

export default Home;
