import { message } from 'antd';
import CardButtons from 'components/card-buttons';
import LabelInput from 'components/label-input';
import LabelTextarea from 'components/label-textarea';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Card, Col, Row } from 'reactstrap';
import { API, PATH } from 'services/axios';
import styles from './vendorProfile.module.css'


const EditDetails = ({ handleCancel, id }) => {
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        address: ''
    });
    const [phone, setPhone] = useState('');

    const getVendorDetails = async () => {
        try {
            const result = await API.get(PATH.getVendorDetails(id));
            console.log(result);
            if (result) {
                setFormData(result.data[0]);
                setPhone(result.data[0]?.phone);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getVendorDetails();
    }, []);

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };


    const handleSave = async () => {
        // console.log({ ...formData, phone: `+${phone}` });
        const validate = formData.firstname &&
            formData.lastname &&
            formData.address &&
            phone;
        if (validate) {

            try {
                const result = await API.patch(PATH.updateVendorDetails(id), { ...formData, phone: `+${phone.replace('+', '')}` });
                console.log(result);
                if (result) {
                    // console.log('update Details ', result);
                    handleCancel();
                }
            } catch (err) {
                console.log(err);
            }

        } else {
            message.error('Fill up all field')
        }
    };



    return (
        <div>
            <Row>
                <Col>
                    <Card className={styles.card}>
                        <LabelInput
                            labelColor="#23314B"
                            label="Firstname"
                            placeholder="Firstname"
                            classes="m-0"
                            name='firstname'
                            value={formData.firstname}
                            onChange={onChange}
                        />
                        <LabelInput
                            labelColor="#23314B"
                            label="Lastname"
                            placeholder="Lastname"
                            name='lastname'
                            value={formData.lastname}
                            onChange={onChange}
                        />
                        <LabelInput
                            labelColor="#23314B"
                            label="Email"
                            placeholder="Enter email"
                            name='email'
                            value={formData.email}
                            disabled={true}
                        />

                        <div className='mt-3'>
                            <p
                                className={styles.label}
                                style={{ color: "#23314B", fontSize: "16px" }}
                            >
                                Phone Number
                            </p>
                            <div style={{ height: "48px" }}>
                                <PhoneInput
                                    country={'us'}
                                    inputStyle={{ width: '100%', height: "48px" }}
                                    value={phone}
                                    onChange={phone => setPhone(phone)}
                                />

                            </div>

                        </div>

                        <LabelTextarea
                            labelColor="#23314B"
                            label="Address"
                            placeholder="Enter address here"
                            rows={2}
                            name='address'
                            value={formData?.address || ''}
                            onChange={onChange}
                        />
                        <CardButtons handleCancel={handleCancel} handleSave={handleSave} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default EditDetails;