import { useEffect, useState, useRef, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";
import styles from "./admins.module.css";
import SearchPage from "components/search-page";
import RoleToggle from "components/role-toggle";
import SwitchInput from "components/switch-input";
import {
  deleteAdmin,
  getAdmins,
  updateAdmin,
} from "redux/slices/admin/admin.async";
import {
  resetAdminUsers,
  resetCall,
  updateAdminUsers,
} from "redux/slices/admin/admin.slice";
import pencilImg from "assets/icons/pencil-yellow.svg";
import deleteImg from "assets/icons/delete-red.svg";
import plusImg from "assets/icons/plus-white.svg";
import ModalEmpty from "components/modal-empty";
import AddEditAdminCard from "components/add-edit-admin-card";
import ConfirmCard from "components/confirm-card";
import notify from "services/toastify";
import { trimString } from "utils";
import { Table } from "antd";
import { useSearchParams } from "react-router-dom";

const limit = 10;

const Admins = () => {
  const { adminUsers, loading, isSuccess, isError } = useSelector(
    (state) => state.admin,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);
  const [editAdmin, setEditAdmin] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [search, setSearch] = useState("");

  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    if (isError) {
      dispatch(resetCall());
      notify(isError, 0);
    } else if (isSuccess) {
      dispatch(resetCall());
      notify(isSuccess);
      if (currentData) {
        const newArr = adminUsers.map((x) =>
          x.id === currentData.id ? currentData : x
        );
        // console.log(newArr);
        setCurrentData(null);
        dispatch(updateAdminUsers(newArr));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);



  // START INFINITE SCROLL
  const offset = useRef(0);

  useEffect(() => {
    dispatch(getAdmins({ offset: offset.current, limit }));

    return () => {
      dispatch(resetAdminUsers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // END INFINITE SCROLL

  const handleUpdateAdmin = (data) => {
    if (data.role === "superadmin") {
      notify("SuperAdmin should't be InActive.");
      return;
    }
    const newData = { ...data, active: data.active ? 0 : 1 };
    setCurrentData(newData);
    dispatch(updateAdmin(newData));
  };

  const handleDeleteAdmin = () => {
    if (currentId) {
      dispatch(deleteAdmin({ id: currentId }))
        .then((res) => {
          console.log("Res", res);
          const { status, message } = res.payload;
          if (status) {
            const newData = adminUsers.filter((x) => x.id !== currentId);
            dispatch(updateAdminUsers(newData));
            setIsConfirmOpen(false);
            setCurrentId(false);
          } else {
            notify(message, 0);
          }
        })
        .catch((err) => {
          console.log("Err", err);
          notify("Something went wrong!!!");
        });
    }
  };

  // START LOCAL SEARCH
  const searchForData = (arr, s) => {
    s = trimString(s);
    return arr.filter((item, index) => {
      return (
        (item.name.toLowerCase() && item.name.toLowerCase().includes(s)) ||
        (item.email.toLowerCase() && item.email.toLowerCase().includes(s))
      );
    });
  };

  // const searchData = useMemo(() => {
  //   return search.length > 0
  //     ? searchForData(adminUsers, search.toLowerCase())
  //     : adminUsers;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search, adminUsers, currentData?.active]);
  // END LOCAL SEARCH

  const columns = [
    {
      title: 'Name',
      width: '20%',
      render: ({ name }) => <span style={{ whiteSpace: "nowrap" }}>{name}</span>,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
      width: '20%',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '20%',
      filters: [
        {
          text: 'Superadmin',
          value: 'superadmin',
        },
        {
          text: 'Admin',
          value: 'admin',
        },
      ],
      onFilter: (value, record) => record.role.toLowerCase() === value.toLowerCase(),
    },
    {
      title: 'Active',
      width: '10%',
      render: ({ id, name, email, role, active }) => <SwitchInput
        name={`admin_active_${id}`}
        checked={Boolean(active)}
        setChecked={() =>
          handleUpdateAdmin({ id, name, email, role, active })
        }
      />
    },
    {
      title: ' ',
      width: '20%',
      render: ({ id, name, email, role, active, photo }) => <div style={{ whiteSpace: 'nowrap' }}>
        <img
          src={pencilImg}
          alt="Edit"
          className={styles.pointer}
          onClick={() => {
            setEditAdmin({
              id,
              name,
              email,
              role,
              active,
              photo: "",
            });
            setIsOpen(true);
          }}
        />
        <img
          src={deleteImg}
          className={styles.pointer}
          alt="Delete"
          onClick={() => {
            setCurrentId(id);
            setIsConfirmOpen(true);
          }}
        />
      </div>
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setSearchParams({
      page: pagination.current,
      size: pagination.pageSize
    });
    dispatch(getAdmins({
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize
    }));
  };

  return (
    <div className={styles.wrapper}>
      <ModalEmpty paddingTop="10vh" isOpen={isOpen} setIsOpen={setIsOpen}>
        <AddEditAdminCard
          title={`${editAdmin ? "Edit" : "Add"} Admin`}
          editAdmin={editAdmin}
          setIsOpen={setIsOpen}
        />
      </ModalEmpty>

      <ModalEmpty
        paddingTop="25vh"
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      >
        <ConfirmCard
          handleSave={handleDeleteAdmin}
          setIsOpen={setIsConfirmOpen}
        />
      </ModalEmpty>

      <SearchPage title="Admins" search={search} setSearch={setSearch}>
        <button
          className={styles.addBtn}
          onClick={() => {
            setEditAdmin(null);
            setIsOpen(true);
          }}
        >
          <img src={plusImg} alt="add" />
          Add Admin
        </button>
      </SearchPage>

      <Card className="border-0">

        <Table
          className="mt-3 p-3 overflow-auto"
          columns={columns}
          dataSource={search.length > 0
            ? searchForData(adminUsers, search.toLowerCase())
            : adminUsers}
          onChange={onChange}
          pagination={{
            defaultPageSize: Number(searchParams.get('size')) || 10,
            defaultCurrent: Number(searchParams.get('page')) || 1,
            total: adminUsers ? adminUsers[0]?.total : 10
          }}
        />

      </Card>

    </div>
  );
};

export default Admins;
