import CheckboxInput from "components/checkbox-input";
import React from "react";
// import { Table } from "reactstrap";
import styles from "./orders-table.module.css";

import moreImg from "assets/icons/more.svg";
import moment from 'moment';
import { Table } from "antd";

const OrdersTable = ({ orders }) => {
  const columns = [
    // {
    //   title: ' ',
    //   render: () => <CheckboxInput />,
    // },
    {
      title: 'Order ID',
      render: ({ cartId }) => `#${cartId}`,
      sorter: (a, b) => a.cartId.localeCompare(b.cartId)
    },
    {
      title: 'Customer',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Items',
      render: ({ products }) => <span>{products.length}</span>,
      sorter: (a, b) => a.products.length - b.products.length,
    },
    {
      title: 'Date',
      render: ({ createdAt }) => <span style={{whiteSpace:'nowrap'}}>{moment(createdAt).format('DD MMM, YYYY')}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: 'Total',
      render: ({ orderTotal }) => <span>$ {orderTotal}</span>,
    },
    {
      title: 'Type',
      render: ({ delivery }) => delivery ? 'Delivery' : 'DineIn',
    },
    {
      title: ' ',
      render: ({ status }) => <div className={styles.status}>{status}</div>,
    },
    {
      title: ' ',
      render: () => <img src={moreImg} alt="more" />,
      width: '5%',
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <>
      <Table
        className="mt-3 p-3 overflow-auto"
        columns={columns}
        dataSource={orders}
        onChange={onChange}
      />

    </>


  );
};

export default OrdersTable;
