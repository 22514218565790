import React from "react";
import cls from "classnames";
import styles from "./card-buttons.module.css";

const CardButtons = ({
  cancelText = "Cancel",
  handleCancel,
  saveText = "Save",
  handleSave,
  alignSelf = "center",
  loading,
}) => {
  return (
    <div style={{ marginTop: 35, alignSelf }}>
      <button
        onClick={handleCancel}
        className={cls(styles.button, styles.cancel)}
      >
        {cancelText}
      </button>
      <button
        disabled={loading}
        onClick={handleSave}
        className={cls(styles.button, styles.save, {
          [styles.disabled]: loading,
        })}
      >
        {saveText}
      </button>
    </div>
  );
};

export default CardButtons;
