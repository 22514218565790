import { toast } from "react-toastify";

const types = {
  1: "success",
  0: "error",
  2: "warning",
  3: "info",
  4: "default",
};

const notify = (message = "Success", variant = 1) => {
  toast[types[variant]](message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  });
};

export default notify;
