import { useState } from "react";
import styles from "./switch-input.module.css";

const SwitchInput = ({ name, checked, setChecked }) => {
  // const [checked, setChecked] = useState(on);

  return (
    <>
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        className={styles.inputCheckbox}
        onChange={(e) => setChecked(!e.target.checked)}
      />
      <nav className={styles.nav}>
        <label
          htmlFor={name}
          className={styles.checkbox}
          //onClick={() => setChecked(!checked)}
        ></label>
      </nav>
    </>
  );
};

export default SwitchInput;
