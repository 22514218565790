import axios from "axios";
import { getUserRole } from "utils";

const BASE_URL = `https://api.netwotap.com/${getUserRole().toLowerCase()}/`;


const PATH = {
  login: "login",
  changePassword: "change-password",
  getAdmins: (offset, limit) => `?offset=${offset}&limit=${limit}`,
  addAdmin: "",
  updateAdmin: (id) => `${id}`,
  deleteAdmin: (id) => `${id}`,
  getUsers: (offset, limit) => `users?offset=${offset}&limit=${limit}`,
  updateIsPro: "updatePro",
  updateUser: "updateUser",
  getUserDetails: (uid) => `users/${uid}`,
  getDashboard: "dashboard",
  getConnections: (offset, limit) => `all-connections?offset=${offset}&limit=${limit}`,
  getCampaigns: (offset, limit) => `campaigns?offset=${offset}&limit=${limit}`,
  sendNotification: "campaigns/send-notification",
  sendEmail: "campaigns/send-email",
  getSales: (offset, limit) => `sales?offset=${offset}&limit=${limit}`,
  getGroups: "groups",
  getGroupDetails: (id) => `groups/${id}`,
  addGroup: "groups",
  updateGroup: (id) => `groups/${id}`,
  deleteGroup: (id) => `groups/${id}`,
  addUserToGroup: (groupId) => `group-users/${groupId}`,
  deleteUserFromGroup: (groupId) => `group-users/${groupId}`,

  getConnectionGroups: "connection-groups",
  getConnectionGroupDetails: (id) => `connection-groups/${id}`,
  addConnectionGroup: "connection-groups",
  updateConnectionGroup: (id) => `connection-groups/${id}`,
  deleteConnectionGroup: (id) => `connection-groups/${id}`,
  addUserToConnectionGroup: (groupId) => `connection-group-users/${groupId}`,
  deleteUserFromConnectionGroup: (groupId) => `connection-group-users/${groupId}`,

  getQRlinks: "qr-links",
  generateQRlinks: (count) => `qr-links/${count}`,
  getVendors: 'vendors',
  addVendor: "vendor",
  getVendorDetails: (id) => `vendors/${id}`,
  updateVendorDetails: (id) => `vendor/${id}`,
  getVendorEmployees: (uid) => `vendor-emps/${uid}`,
  addVendorEmployee: (uid) => `vendor-emp/${uid}`,
  addUser: "user",
};

const API = {
  get: (url) => {
    console.log(">get>", url);
    return new Promise((resolve, reject) => {
      return axios
        .get(url)
        .then((res) => {
          // console.log(res.data);
          resolve(res.data);
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },

  post: (url, data) => {
    console.log(">post>", url);
    return new Promise((resolve, reject) => {
      return axios
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  postForm: (url, data) => {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

    console.log(">postForm>", url);
    return new Promise((resolve, reject) => {
      return axios
        .post(url, data, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  put: (url, data) => {
    console.log(">put>", url);
    return new Promise((resolve, reject) => {
      return axios
        .put(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  delete: (url, data = {}) => {
    console.log(">delete>", url);
    return new Promise((resolve, reject) => {
      return axios
        .delete(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  patch: (url, data) => {
    console.log(url);
    return new Promise((resolve, reject) => {
      return axios
        .patch(url, data)
        .then((res) => {
          // console.log(res.data);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// const API = {
//   get: (url, token) => {
//     axios.defaults.headers.common.Authorization = token
//       ? "Bearer " + token
//       : null;

//     console.log(url);
//     return new Promise((resolve, reject) => {
//       return axios
//         .get(url)
//         .then((res) => {
//           // console.log(res.data);
//           resolve(res.data);
//         })
//         .catch((error) => {
//           // console.log(error.response);
//           reject(error?.response || error.message || error.toString());
//           if (error?.response?.status === 401) {
//             console.log("Unauthorized user");
//           }
//         });
//     });
//   },

//   post: (url, data, token) => {
//     axios.defaults.headers.common.Authorization = token
//       ? "Bearer " + token
//       : null;

//     console.log(">>", url);
//     return new Promise((resolve, reject) => {
//       return axios
//         .post(url, data)
//         .then((res) => {
//           // console.log('Res => api.js', res);
//           resolve(res.data);
//         })
//         .catch((error) => {
//           // console.log(error.response);
//           reject(error?.response || error.message || error.toString());
//           if (error?.response?.status === 401) {
//             console.log("Unauthorized user");
//           }

//           // if (error.response) {
//           //   console.log(
//           //     '🚀 ~ file: api.js ~ line 73 ~ return newPromise ~ error.response',
//           //     error.response,
//           //   );
//           // } else if (error.request) {
//           //   console.log(
//           //     '🚀 ~ file: api.js ~ line 76 ~ return newPromise ~ error.request',
//           //     error.request,
//           //   );
//           // } else if (error.message) {
//           //   console.log(
//           //     '🚀 ~ file: api.js ~ line 78 ~ return newPromise ~ error.message',
//           //     error.message,
//           //   );
//           // }
//         });
//     });
//   },

//   postForm: (url, data, token) => {
//     axios.defaults.headers.common.Authorization = token
//       ? "Bearer " + token
//       : null;
//     axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

//     console.log(url);
//     return new Promise((resolve, reject) => {
//       return axios
//         .post(url, data, { withCredentials: true })
//         .then((res) => {
//           // console.log(res.data);
//           resolve(res.data);
//         })
//         .catch((error) => {
//           // console.log(error.response);
//           reject(error?.response || error.message || error.toString());
//           if (error?.response?.status === 401) {
//             console.log("Unauthorized user");
//           }
//         });
//     });
//   },

//   put: (url, data, token) => {
//     axios.defaults.headers.common.Authorization = token
//       ? "Bearer " + token
//       : null;

//     console.log(url);
//     return new Promise((resolve, reject) => {
//       return axios
//         .put(url, data)
//         .then((res) => {
//           // console.log(res.data);
//           resolve(res.data);
//         })
//         .catch((error) => {
//           // console.log(error.response);
//           reject(error?.response || error.message || error.toString());
//           if (error?.response?.status === 401) {
//             console.log("Unauthorized user");
//           }
//         });
//     });
//   },

//   delete: (url, token) => {
//     axios.defaults.headers.common.Authorization = token
//       ? "Bearer " + token
//       : null;

//     console.log(url);
//     return new Promise((resolve, reject) => {
//       return axios
//         .delete(url)
//         .then((res) => {
//           // console.log(res.data);
//           resolve(res.data);
//         })
//         .catch((error) => {
//           // console.log(error.response);
//           reject(error?.response || error.message || error.toString());
//           if (error?.response?.status === 401) {
//             console.log("Unauthorized user");
//           }
//         });
//     });
//   },

//   patch: (url, data, token) => {
//     axios.defaults.headers.common.Authorization = token
//       ? "Bearer " + token
//       : null;

//     console.log(url);
//     return new Promise((resolve, reject) => {
//       return axios
//         .patch(url, data)
//         .then((res) => {
//           // console.log(res.data);
//           resolve(res.data);
//         })
//         .catch((error) => {
//           // console.log(error.response);
//           reject(error?.response || error.message || error.toString());
//           if (error?.response?.status === 401) {
//             console.log("Unauthorized user");
//           }
//         });
//     });
//   },
// };

export { API, PATH, BASE_URL };
