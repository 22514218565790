import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./search-page.module.css";
import CampaignDropdown from "./campaign-dropdown";

import searchImg from "assets/icons/search-blue.svg";
import arrowLeftImg from "assets/icons/arrow-left.svg";

const SearchPage = ({
  title,
  onTitleClick = null,
  isGoBack = false,
  isSearchBar = true,
  search,
  setSearch,
  isCampaign = false,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <header>
      <div className={styles.wrapper}>
        <div className="d-flex align-items-center">
          {isGoBack && (
            <div
              onClick={() => navigate(-1)}
              // onClick={() => navigate(-1,  { replace: true })}
              className={styles.backBtn}
            >
              <img src={arrowLeftImg} alt="Go Back" />
            </div>
          )}
          <span onClick={onTitleClick} className={styles.title}>
            {title}
          </span>
        </div>
        <div className="d-flex align-items-center">
          {isSearchBar && (
            <div className={styles.searchBar}>
              <img src={searchImg} alt="Search" className={styles.searchImg} />
              <input
                type="text"
                placeholder="Search here"
                className={styles.searchInput}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          )}
          {isCampaign && <CampaignDropdown />}
          {Boolean(children) && children}
        </div>
      </div>
    </header >
  );
};

export default SearchPage;
