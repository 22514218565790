import React, { useState, useMemo } from "react";
import { Card } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import CardSearchBar from "components/card-searchbar";

import styles from "./user-links.module.css";
import CheckboxInput from "components/checkbox-input";
import SwitchInput from "components/switch-input";

import moreImg from "assets/icons/more.svg";
import { trimString } from "utils";
import { Table } from "antd";

const UserLinks = ({ links }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState("");

  // START LOCAL SEARCH
  const searchForData = (arr, s) => {
    s = trimString(s);
    return arr.filter((item, index) => {
      return (
        (item.title?.toLowerCase() && item.title?.toLowerCase().includes(s)) ||
        (item.value.toLowerCase() && item.value.toLowerCase().includes(s)) ||
        (item.type.toLowerCase() && item.type.toLowerCase().includes(s))
      );
    });
  };

  const searchData = useMemo(() => {
    return search.length > 0
      ? searchForData(links, search.toLowerCase())
      : links;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, links?.length]);
  // END LOCAL SEARCH

  const navToAdd = () => {
    navigate(`/qr-links/add-edit`);
  };

  const columns = [
    {
      title: ' ',
      width: '5%',
      render: () => <CheckboxInput />,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '10%',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Links',
      // dataIndex: 'value',
      render: ({value}) => <span className={styles.linkWord}>{value}</span>,
      width: '30%',
    },
    {
      title: 'Title',
      dataIndex: 'type',
      width: '20%',
    },
    {
      title: 'Taps',
      dataIndex: 'taps',
      width: '10%',
      sorter: (a, b) => parseFloat(a.taps) - parseFloat(b.taps),
    },
    {
      title: 'Highlighted',
      width: '10%',
      render: ({highlight}) => <SwitchInput checked={Boolean(highlight)} />,
      filters: [
        {
          text: 'True',
          value: 1,
        },
        {
          text: 'False',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.highlight === value,
    },
    {
      title: 'Active',
      width: '10%',
      render: ({active}) => <SwitchInput checked={Boolean(active)} />,
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.active === value,
    },
    {
      title: ' ',
      width: '5%',
      render: () =>  <img src={moreImg} alt="more" />,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <Card className={styles.card}>
      <CardSearchBar
        title="User Links"
        // rightBtnText="Add Link"
        // handleRightBtn={navToAdd}
        search={search}
        setSearch={setSearch}
      />

      <Table
        className="mt-3 p-3 overflow-auto"
        columns={columns}
        dataSource={searchData}
        onChange={onChange}
      />

    </Card>
  );
};

export default UserLinks;
