import React, { useState } from "react";
import {
  Card,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Col,
} from "reactstrap";
import styles from "./user-profile-card.module.css";

import moreImg from "assets/icons/more-dots-black.svg";
import chevronImg from "assets/icons/chevron-down.svg";

const durationsData = ["Week", "Month", "Year"];

const UserProfileCard = ({ analytics }) => {
  const [duration, setDuration] = useState(durationsData[0]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDDitem = (item) => {
    setDuration(item);
  };

  return (
    <Card className={styles.card}>
      <div className="d-flex align-items-center justify-content-between">
        <p className={styles.header}>
          User Analytics <span>(Profile)</span>
        </p>
        <img src={moreImg} alt="more" />
      </div>
      <div className="d-flex align-items-center justify-content-between mt-4">
        <p className={styles.title}>Primary Stats</p>

        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle className={styles.ddToggle}>
            {duration}
            <img src={chevronImg} alt="toggle" />
          </DropdownToggle>
          <DropdownMenu className={styles.ddMenu}>
            {durationsData.map((x) => (
              <DropdownItem
                key={x}
                onClick={() => handleDDitem(x)}
                className={styles.ddItem}
              >
                {x}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>

      <Row>
        <Col>
          <div className={styles.box}>
            <p className={styles.name}>Interactions</p>
            <p className={styles.count}>{analytics?.[duration.toLowerCase()]?.interactions}</p>
          </div>
        </Col>
        <Col>
          <div className={styles.box}>
            <p className={styles.name}>Profile Visits</p>
            <p className={styles.count}>{analytics?.[duration.toLowerCase()]?.profileViews}</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.box}>
            <p className={styles.name}>Links Tapped</p>
            <p className={styles.count}>{analytics?.[duration.toLowerCase()]?.linkTaps}</p>
          </div>
        </Col>
        <Col>
          <div className={styles.box}>
            <p className={styles.name}>Saved Contact</p>
            <p className={styles.count}>{analytics?.[duration.toLowerCase()]?.savedContacts}</p>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default UserProfileCard;
