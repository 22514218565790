import { createSlice } from "@reduxjs/toolkit";
import { getAdmins, updateAdmin } from "./admin.async";

const initialState = {
  loading: false,
  adminUsers: [],
  isSuccess: false,
  isError: false,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetAdminUsers: (state) => {
      state.loading = false;
      state.adminUsers = [];
    },
    updateAdminUsers: (state, action) => {
      state.adminUsers = action.payload;
    },
    resetCall(state, action) {
      state.loading = false;
      state.isError = false;
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [getAdmins.pending]: (state) => {
      state.loading = true;
    },
    [getAdmins.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    [getAdmins.fulfilled]: (state, action) => {
      state.loading = false;
      state.adminUsers = action.payload?.data;
      // console.log(action.payload?.data);
    },
    [updateAdmin.rejected]: (state, action) => {
      state.isError = "Something went wrong";
    },
    [updateAdmin.fulfilled]: (state, action) => {
      if (action.payload?.status) {
        state.isSuccess = action.payload.message;
      } else {
        state.isError = action.payload.message;
      }
    },
  },
});

export const { resetAdminUsers, updateAdminUsers, resetCall } =
  adminSlice.actions;
export default adminSlice.reducer;
