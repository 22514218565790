import React, { useState } from "react";
import { Card, Col, Row, TabContent, TabPane } from "reactstrap";
import styles from "./user-orders.module.css";
import OrdersTabBar from "components/orders-tabbar";
import OrdersTable from "components/orders-table";

const UserOrders = ({ orders }) => {
  const [activeTab, setActiveTab] = useState("upcoming"); // "upcoming" || "past"
  return (
    <Card className={styles.card}>
      <p className={styles.cardTitle}>User Orders</p>

      <OrdersTabBar activeTab={activeTab} setActiveTab={setActiveTab} />

      <TabContent activeTab={activeTab}>
        <TabPane tabId="upcoming">
          <Row>
            <Col>
              <OrdersTable orders={orders?.filter(x => x.status !== 'completed')} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="past">
          <Row>
            <Col>
              <OrdersTable orders={orders?.filter(x => x.status === 'completed')} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </Card>
  );
};

export default UserOrders;
