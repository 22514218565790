// import { useState } from "react";
// import { Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SwitchInput from "components/switch-input";
import CheckboxInput from "components/checkbox-input";
import styles from "./connections-table.module.css";

import moreImg from "assets/icons/more.svg";
import { Table } from "antd";

const ConnectionsTable = ({ classes = "", data }) => {
  const navigate = useNavigate();

  const navToUserDetails = (e, uid) => {
    e.stopPropagation();
    navigate(`/users/${uid}`);
  };

  const columns = [
    {
      title: ' ',
      render: () => <CheckboxInput />
    },
    {
      title: 'Name',
      render: ({ uid, photo, name }) => <div
        onClick={(e) => navToUserDetails(e, uid)}
        className="d-flex align-items-center pointer"
      >
        {photo && photo !== 'null' ? <img src={photo} alt={name} className={styles.avatar} /> : <div className={styles.avatar} />}
        <span style={{whiteSpace:'nowrap'}}>{name}</span>
      </div>,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
    },
    {
      title: 'City',
      dataIndex: 'city',
      sorter: (a, b) => a.city.localeCompare(b.city)
    },
    {
      title: 'Job Title',
      render: ({jobTitle}) => jobTitle && jobTitle !== 'null' ? jobTitle : "--" 
    },
    {
      title: ' ',
      render: () => <img src={moreImg} alt="more" />,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <>
      <Table
        className="mt-3 p-3 overflow-auto"
        columns={columns}
        dataSource={data}
        onChange={onChange}
      />


    </>
  );
};

export default ConnectionsTable;
