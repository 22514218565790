import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import styles from "./login.module.css";
import LabelInput from "components/label-input";
import { getUserRole, validateEmail } from "utils";
import logoImg from "assets/icons/logo.png";
import notify from "services/toastify";
import { adminLogin } from 'redux/slices/auth/auth.async';
import { signInWithEmailAndPassword } from "firebase/auth";
import auth from "../../firebase.auth";
import { setUser } from "redux/slices/auth/auth.slice";
import { async } from "@firebase/util";

const Login = () => {
  const { user } = useSelector(state => state.auth, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (user) {
      navigate("/home");
    }
  }, [navigate, user]);

  const handleLogin = () => {
    const validConditions = validateEmail(email) && password.length >= 6;
    if (!validConditions) {
      notify("Please enter valid information", 0);
      return;
    }

    if (getUserRole() === 'Admin') {

      const data = { email, password };
      dispatch(adminLogin(data)).then(({ error }) => {
        if (error) {
          notify("Invalid Email or Password", 0);
        } else {
          notify("Logged in successfully");
        }
      }).catch(err => {
        notify("Invalid Email or Password", 0);
      });

    } else {

      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in 
          const vendorUser = userCredential.user;
          console.log('vendor login', vendorUser);
          notify("Logged in successfully");
          
          const token = await auth.currentUser.getIdToken();

          dispatch(setUser({
            name: vendorUser.displayName,
            email: vendorUser.email,
            role: 'Vendor',
            token: token,
            uid: auth.currentUser.uid
          }))
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.log('vendor error', errorMessage);
          notify("Invalid Email or Password", 0);
        });
    }
  }



  return (
    <div className={styles.wrapper}>
      <Card className={styles.card}>
        <Container fluid>
          <Row>
            <Col className="p-0">
              <div className="d-flex align-items-center">
                <img height={40} src={logoImg} alt="Netwotap" />
                <p className={styles.logoText}>Netwotap</p>
              </div>
              <p className={styles.title}>{getUserRole()} LogIn</p>
              <LabelInput
                label="Email address"
                classes="mt-4"
                type="email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
              />
              <LabelInput
                label="Password"
                isSecure
                placeholder="Enter Password"
                value={password}
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button onClick={handleLogin} className={styles.btn}>
                Login
              </button>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  );
};

export default Login;
