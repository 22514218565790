import { Card, Col, Row } from "reactstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./connections.module.css";
import SearchPage from "components/search-page";
import usersImg from "assets/icons/users-circle.svg";
import plusImg from "assets/icons/plus-black.svg";
import CountCard from "components/count-card";
import { useEffect, useRef, useState } from "react";
import CardSearchBar from "components/card-searchbar";
import ModalEmpty from "components/modal-empty";
import { getUserRole, trimString } from "utils";
import AddToConnectionsGroup from "components/AddToConnectionsGroup/AddToConnectionsGroup";
import CreateEditConnectionGroupCard from "components/create-edit-connection-groupCard/CreateEditConnectionGroupCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getConnections } from '../../redux/slices/connections/connections.async'
import { resetConnections } from '../../redux/slices/connections/connections.slice'
import ConnectionsTable from "components/admin-connections-table/ConnectionsTable";
import { getConnectionGroups } from "redux/slices/connectionGroup/connectionGroup.async";


const Connections = () => {
  const { connections } = useSelector((state) => state.connections, shallowEqual);
  const { data, loading: groupLoading } = useSelector(
    (state) => state.connectionGroups,
    shallowEqual
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isGroupOpen, setIsGroupOpen] = useState(false);

  const [search, setSearch] = useState("");

  const [selected, setSelected] = useState([]);

  const limit = 10;

  const navToAdd = () => {
    navigate(`${location.pathname}/add-edit`);
  };

  const navToGroupDetails = (id) => {
    navigate(`${location.pathname}/group/${id}`);
  };

  // START INFINITE SCROLL
  const offset = useRef(0);


  useEffect(() => {
    dispatch(getConnections({ offset: offset.current, limit }));

    if (getUserRole() === 'Admin') {
      dispatch(getConnectionGroups());
    }
    return () => {
      dispatch(resetConnections());
    };
  }, []);


  // getConnections

  // START LOCAL SEARCH
  const searchForData = (arr, s) => {
    s = trimString(s);
    return arr.filter((item, index) => {
      return (
        (item?.name?.toLowerCase() && item?.name?.toLowerCase().includes(s)) ||
        (item?.email?.toLowerCase() && item?.email?.toLowerCase().includes(s))
      );
    });
  };

  // const searchData = useMemo(() => {
  //   return search.length > 0
  //     ? searchForData(connections, search.toLowerCase())
  //     : connections;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search, connections]);
  // END LOCAL SEARCH

  return (
    <div className={styles.wrapper}>

      {getUserRole() === 'Admin' ? <>
        <ModalEmpty paddingTop="25vh" isOpen={isOpen} setIsOpen={setIsOpen}>
          <AddToConnectionsGroup
            setIsOpen={setIsOpen}
            options={data}
            selected={selected}
          />
        </ModalEmpty>

        <ModalEmpty
          paddingTop="25vh"
          isOpen={isGroupOpen}
          setIsOpen={setIsGroupOpen}
        >
          <CreateEditConnectionGroupCard
            title="Create New Group"
            setIsOpen={setIsGroupOpen}
          />
        </ModalEmpty>

        <>
          <SearchPage title="Connection Groups" isSearchBar={false} />

          <div className="d-flex flex-wrap gap-3">
            {data?.length > 0 &&
              data.map((item) => (
                <div
                  key={item.id}
                  onClick={() => navToGroupDetails(item.id)}
                  className="pointer"
                >
                  <CountCard
                    photo={usersImg}
                    name={item.name}
                    count={item.users?.length}
                  />
                </div>
              ))}
            <div >
              <Card
                onClick={() => setIsGroupOpen(true)}
                className={styles.addCard}
              >
                <img src={plusImg} alt="Add" />
                <p>Create New Group</p>
              </Card>
            </div>
          </div>

          <SearchPage title="Connections List" isSearchBar={false} />

          <Card className={styles.card}>
            <CardSearchBar
              //ref={searchBarRef}
              search={search}
              setSearch={setSearch}
              isRightBtn={selected.length > 0}
              rightBtnText2="Add to group"
              handleRightBtn2={() => setIsOpen(true)}
              rightBtnText="Add Connections"
              handleRightBtn={navToAdd}
            />

            <ConnectionsTable
              dataSource={search.length > 0
                ? searchForData(connections, search.toLowerCase())
                : connections}
              selected={selected}
              setSelected={setSelected}
              connections={connections}
            />

          </Card>
        </>
      </> :
        <>
          <SearchPage
            title="Connections"
            onTitleClick={navToAdd}
            search={search}
            setSearch={setSearch}
          />

          <Card className="border-0">
            {/* <Table
              className="mt-3 p-3 overflow-auto"
              columns={columns}
              dataSource={search.length > 0
                ? searchForData(connections, search.toLowerCase())
                : connections}
              onChange={onChange}
              pagination={{
                total: connections ? connections[0]?.total : 10
              }}
            /> */}
            <ConnectionsTable
              dataSource={search.length > 0
                ? searchForData(connections, search.toLowerCase())
                : connections}
              selected={selected}
              setSelected={setSelected}
              connections={connections}
            />
          </Card>
        </>}
    </div>
  );
};

export default Connections;
