import React, { useState } from "react";
import styles from "./user-products-card.module.css";

import chevronRight from "assets/icons/chevron-right.svg";
import chevronDown from "assets/icons/chevron-down.svg";
import { Collapse } from "reactstrap";

const CollapseList = ({ category }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mt-3">
      <div onClick={() => setIsOpen(!isOpen)} className={styles.collapseHeader}>
        <p>{category.name}</p>
        <img src={isOpen ? chevronDown : chevronRight} alt="toggle" />
      </div>
      <Collapse isOpen={isOpen}>
        <div className={styles.collapseBody}>
          {category?.products?.map(({ id, name, description, image, mrp, price}) => (
            <div key={id} className="d-flex align-items-center mb-2">
              <p className={styles.prodName}>{name}</p>
              <p className={styles.prodMrp}>${mrp}</p>
              <p className={styles.prodPrice}>${price}</p>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapseList;
