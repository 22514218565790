import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "reactstrap";
import CardButtons from "components/card-buttons";
import LabelInput from "components/label-input";
import {
  addGroup,
  getGroupDetails,
  getGroups,
  updateGroup,
} from "redux/slices/groups/groups.async";
import notify from "services/toastify";
import styles from "./create-edit-group-card.module.css";
import { colors } from "styles/theme";

const CreateEditGroupCard = ({ title = "", id, name: oldName, setIsOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(id ? oldName : "");

  const handleSave = () => {
    setLoading(true);
    if (id) {
      dispatch(updateGroup({ id, name }))
        .then((res) => {
          console.log("Res", res);
          dispatch(getGroupDetails({ id }));
          setLoading(false);
          const { status, message } = res.payload;
          if (status) {
            // notify(message);
            dispatch(getGroups());
            setIsOpen(false);
          } else {
            notify(message, 0);
          }
        })
        .catch((err) => {
          console.log("Err", err);
          setLoading(false);
          notify("Something went wrong!!!");
        });
    } else {
      dispatch(addGroup({ name }))
        .then((res) => {
          console.log("Res", res);
          setLoading(false);
          const { status, message } = res.payload;
          if (status) {
            // notify(message);
            dispatch(getGroups());
            setIsOpen(false);
          } else {
            notify(message, 0);
          }
        })
        .catch((err) => {
          console.log("Err", err);
          setLoading(false);
          notify("Something went wrong!!!");
        });
    }
  };

  return (
    <Card className={styles.card}>
      <p className={styles.title}>{title}</p>
      <LabelInput
        classes="mt-4"
        labelColor={colors.tableText}
        label="Group name"
        placeholder="Group name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <CardButtons
        handleCancel={() => setIsOpen(false)}
        handleSave={handleSave}
        loading={loading}
      />
    </Card>
  );
};

export default CreateEditGroupCard;
