import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "reactstrap";
import CardButtons from "components/card-buttons";
import LabelInput from "components/label-input";
import notify from "services/toastify";
import styles from "./add-edit-admin-card.module.css";
import { colors } from "styles/theme";
import LabelSelect from "components/label-select";
import {
  addAdmin,
  getAdmins,
  updateAdmin,
} from "redux/slices/admin/admin.async";

const AddEditAdminCard = ({ title = "", editAdmin, setIsOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(
    editAdmin
      ? editAdmin
      : {
          name: "",
          email: "",
          password: "",
          role: "",
          photo: null,
        }
  );

  const { name, email, password, role } = data;

  // console.log(data, editAdmin);

  const handleSave = () => {
    const validConditions = name && email && role;
    if (!validConditions) {
      notify("Please fill all details", 0);
      return;
    }

    setLoading(true);
    if (editAdmin) {
      dispatch(updateAdmin(data))
        .then((res) => {
          console.log("Res", res);
          setLoading(false);
          const { status, message } = res.payload;
          if (status) {
            dispatch(getAdmins({ offset: 0, limit: 50 }));
            setIsOpen(false);
          } else {
            notify(message, 0);
          }
        })
        .catch((err) => {
          console.log("Err", err);
          setLoading(false);
          notify("Something went wrong!!!");
        });
    } else {
      dispatch(addAdmin(data))
        .then((res) => {
          console.log("Res", res);
          setLoading(false);
          const { status, message } = res.payload;
          if (status) {
            dispatch(getAdmins({ offset: 0, limit: 50 }));
            setIsOpen(false);
          } else {
            notify(message, 0);
          }
        })
        .catch((err) => {
          console.log("Err", err);
          setLoading(false);
          notify("Something went wrong!!!");
        });
    }
  };

  const handleChange = (e) => {
    setData({ ...data, role: e.target.value });
  };

  return (
    <Card className={styles.card}>
      <p className={styles.title}>{title}</p>
      <LabelInput
        classes="mt-4"
        labelColor={colors.tableText}
        label="Name"
        placeholder="Enter name"
        value={name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <LabelInput
        classes="mt-4"
        labelColor={colors.tableText}
        label="Email"
        name="email-address"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setData({ ...data, email: e.target.value })}
      />
      {!editAdmin && (
        <LabelInput
          classes="mt-4"
          labelColor={colors.tableText}
          label="Password"
          name="new-address"
          placeholder="Enter password"
          value={password}
          type="password"
          onChange={(e) => setData({ ...data, password: e.target.value })}
        />
      )}
      <LabelSelect
        labelColor={colors.tableText}
        label="Select role*"
        placeholder="Select role"
        classes="mt-4"
        options={[
          { id: "superadmin", name: "SuperAdmin" },
          { id: "admin", name: "Admin" },
        ]}
        value={role}
        onChange={handleChange}
      />
      <CardButtons
        handleCancel={() => setIsOpen(false)}
        handleSave={handleSave}
        loading={loading}
      />
    </Card>
  );
};

export default AddEditAdminCard;
