import styles from "./label-textarea.module.css";

const LabelTextarea = ({
  label,
  value,
  onChange,
  placeholder,
  isRequired = true,
  type = "text",
  classes = "mt-3",
  labelColor = "rgba(35, 49, 75, 0.65)",
  rows = 4,
  ...inputProps
}) => {
  return (
    <div className={classes}>
      <p className={styles.label} style={{ color: labelColor }}>
        {label}
        {isRequired && "*"}
      </p>
      <div className={styles.inputCover}>
        <textarea
          id={label}
          className={styles.input}
          cols="30"
          rows={rows}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          {...inputProps}
        />
      </div>
    </div>
  );
};

export default LabelTextarea;
