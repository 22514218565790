import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import CountCard from "components/count-card";
import SearchPage from "components/search-page";
import SubscriptionsCard from "components/subscriptions-card";
import styles from "./sales.module.css";
import { getSales } from "redux/slices/sales/sales.async";

import blueImg from "assets/icons/analytics-circle-blue.svg";
import yellowImg from "assets/icons/analytics-circle-yellow.svg";
import redImg from "assets/icons/analytics-circle-red.svg";
import purpleImg from "assets/icons/analytics-circle-purple.svg";
import { resetSubscriptions } from "redux/slices/sales/sales.slice";

const limit = 10;

const Sales = () => {
  const {
    data: { today, lastWeek, lastMonth, total },
    subscriptions,
  } = useSelector((state) => state.sales, shallowEqual);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  // START INFINITE SCROLL
  const offset = useRef(0);


  useEffect(() => {
    dispatch(getSales({ offset: offset.current, limit }));

    return () => {
      dispatch(resetSubscriptions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // END INFINITE SCROLL

  return (
    <div className={styles.wrapper}>
      <SearchPage title="Sales" search={search} setSearch={setSearch} />

      <Row className="gy-3">
        <Col>
          <CountCard photo={blueImg} name="Today" count={today} />
        </Col>
        <Col>
          <CountCard photo={redImg} name="Last Week" count={lastWeek} />
        </Col>
        <Col>
          <CountCard photo={purpleImg} name="Last Month" count={lastMonth} />
        </Col>
        <Col>
          <CountCard photo={purpleImg} name="Total" count={total} />
        </Col>
      </Row>

      <Row>
        <Col>
          <SubscriptionsCard
            subscriptions={subscriptions}
            isSearchBar={false}
            extraSearch={search}
          />


        </Col>
      </Row>
    </div>
  );
};

export default Sales;
