import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./search-page.module.css";

import notification from "assets/icons/notification-grey.svg";
import email from "assets/icons/email-grey.svg";
import sms from "assets/icons/sms-grey.svg";

const CampaignDropdown = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navToPage = (route) => {
    navigate(`${pathname}/${route}`);
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle className={styles.campaignButton}>
        New Campaign
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => navToPage("send-notification")}
          className={styles.ddItem}
        >
          <div className={styles.ddItemIcon}>
            <img src={notification} alt="Notification" />
          </div>
          Notification
        </DropdownItem>
        <DropdownItem
          onClick={() => navToPage("send-email")}
          className={styles.ddItem}
        >
          <div className={styles.ddItemIcon}>
            <img src={email} alt="Email" />
          </div>
          Email
        </DropdownItem>
        {/* <DropdownItem
          onClick={() => navToPage("send-sms")}
          className={styles.ddItem}
        >
          <div className={styles.ddItemIcon}>
            <img src={sms} alt="SMS" />
          </div>
          SMS
        </DropdownItem> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default CampaignDropdown;
