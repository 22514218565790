import { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import cls from "classnames";
import CollapseCard from "components/collapse-card";
import LabelInput from "components/label-input";
import LabelTextarea from "components/label-textarea";
import styles from "./notification-preview.module.css";

// import androidImg from "assets/notification/android-screen.png";
// import iosImg from "assets/notification/ios-screen.png";
import placeholder from "assets/notification/placeholder.png";
import notify from "services/toastify";

// { users, title, body, image, name }

const NotificationPreview = ({ isOpen, setIsOpen, handleNotificationData }) => {
  const [activeTab, setActiveTab] = useState("initial"); // "initial" || "expanded"

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [link, setLink] = useState("");

  const handleNext = () => {
    const validConditions = title && body;

    if (!validConditions) {
      notify("Please enter notification title and notification body!!!", 0);
      return;
    }

    handleNotificationData({ title, body, image, name, link });
  };

  const PreviewText = () => (
    <>
      <p className="m-0">{title || "Notification Title"}</p>
      <p className="m-0 small">{body || "Notification Text"}</p>
      {/* {checkEdit() ? (
        <>
          <p className="m-0">{title}</p>
          <p className="m-0 small">{text}</p>
        </>
      ) : (
        <>
          <p className="m-0">Notification Title</p>
          <p className="m-0 small">Notification Text</p>
        </>
      )} */}
    </>
  );

  const LogoMedium = () => (
    <div className="text-center my-2">
      <img
        src={image ? image : placeholder}
        alt="logo"
        className={cls(styles.logoMedium, "img-fluid rounded-sm")}
      />
    </div>
  );

  const LogoSmall = () => (
    <div className="w-25 text-end">
      <img
        src={image ? image : placeholder}
        alt="logo"
        className={cls(styles.logoSmall, "img-fluid rounded-sm")}
      />
    </div>
  );

  const PlatformText = ({ name }) => (
    <h5 className="text-center text-muted my-3 small">{name}</h5>
  );

  return (
    <CollapseCard
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Notification"
      btnText="Next"
      contentClass="p-0"
      onBtnClick={handleNext}
    >
      <Container fluid>
        <Row className="g-0">
          <Col md={5}>
            <LabelInput
              isRequired={false}
              label="Notification Title"
              placeholder="Enter title"
              labelColor="#23314B"
              classes="mt-0"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <LabelTextarea
              isRequired={false}
              label="Notification Text"
              placeholder="Enter notification text"
              labelColor="#23314B"
              rows={3}
              classes="mt-4"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
            <LabelInput
              isRequired={false}
              label="Notification Image (optional)"
              placeholder="Example: https://image.png"
              labelColor="#23314B"
              classes="mt-4"
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />
            <LabelInput
              isRequired={false}
              label="Notification Name (optional)"
              placeholder="Enter optional name"
              labelColor="#23314B"
              classes="mt-4"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <LabelInput
              isRequired={false}
              label="Link (optional)"
              placeholder="Enter link"
              labelColor="#23314B"
              classes="mt-4"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Col>
          <Col md={{ size: 6, offset: 1 }}>
            <Card className={styles.preview}>
              <p className={styles.previewTitle}>Device Preview</p>
              <p className={styles.previewSubTitle}>
                This preview provides a general idea of how your message will
                appear on a mobile device. Actual message rendering will vary
                depending on the device. Test with a real device for actual
                results.
              </p>

              <div className={styles.previewBox}>
                <Nav>
                  <NavItem>
                    <NavLink
                      className={cls(styles.navLink, {
                        [styles.navLinkActive]: activeTab === "initial",
                      })}
                      active={activeTab === "initial"}
                      onClick={() => setActiveTab("initial")}
                    >
                      Initial state
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={cls(styles.navLink, {
                        [styles.navLinkActive]: activeTab === "expanded",
                      })}
                      active={activeTab === "expanded"}
                      onClick={() => setActiveTab("expanded")}
                    >
                      Expanded view
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="initial">
                    <div className="mt-md-4 mt-3 mx-2">
                      <div
                        className={cls(
                          styles.previewBackground,
                          styles.android
                        )}
                      >
                        <Card className="mt-3 p-2">
                          <div className="d-flex">
                            <div className="w-75">
                              <PreviewText />
                            </div>
                            <LogoSmall />
                          </div>
                        </Card>
                      </div>
                      <PlatformText name="Android" />
                      {/* IOS */}
                      <div
                        className={cls(styles.previewBackground, styles.ios)}
                      >
                        <Card className="mt-3 p-2">
                          <div className="d-flex">
                            <div className="w-75">
                              <PreviewText />
                            </div>
                            <LogoSmall />
                          </div>
                        </Card>
                      </div>
                      <PlatformText name="IOS" />
                    </div>
                  </TabPane>
                  {/* EXPANDED TAB */}
                  <TabPane tabId="expanded">
                    <div className="mt-md-4 mt-3 mx-2">
                      <div
                        className={cls(
                          styles.previewBackground,
                          styles.android
                        )}
                      >
                        <Card className="mt-3 p-2">
                          <PreviewText />
                          <LogoMedium />
                        </Card>
                      </div>
                      <PlatformText name="Android" />
                      {/* IOS */}
                      <div
                        className={cls(styles.previewBackground, styles.ios)}
                      >
                        <Card className="mt-3 p-2">
                          <LogoMedium />
                          <PreviewText />
                        </Card>
                      </div>
                      <PlatformText name="IOS" />
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </CollapseCard>
  );
};

export default NotificationPreview;
