import { createSlice } from "@reduxjs/toolkit";
import { adminLogin, changePassword } from "./auth.async";

const initialState = {
  loading: false,
  user: null,
  isSuccess: false,
  isError: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      state.user = null;
    },
    resetCall(state, action) {
      state.loading = false;
      state.isError = false;
      state.isSuccess = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [adminLogin.pending]: (state) => {
      state.loading = true;
    },
    [adminLogin.rejected]: (state) => {
      state.loading = false;
      state.error = true;
      state.user = null;
    },
    [adminLogin.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload?.data;
    },
    [changePassword.pending]: (state, action) => {
      state.loading = true;
    },
    [changePassword.rejected]: (state, action) => {
      state.loading = false;
      state.isError = "Something went wrong";
    },
    [changePassword.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.status) {
        state.isSuccess = action.payload.message;
      } else {
        state.isError = action.payload.message;
      }
    },
  },
});

export const { logout, resetCall, setUser } = authSlice.actions;
export default authSlice.reducer;
