import React from "react";
import { Card } from "reactstrap";
import CardButtons from "components/card-buttons";
import styles from "./confirm-card.module.css";

const ConfirmCard = ({
  title = "Are you sure?",
  loading,
  setIsOpen,
  handleSave,
}) => {
  return (
    <Card className={styles.card}>
      <p className={styles.title}>{title}</p>
      <CardButtons
        handleCancel={() => setIsOpen(false)}
        saveText="Confirm"
        handleSave={handleSave}
        loading={loading}
      />
    </Card>
  );
};

export default ConfirmCard;
