import { Table } from "antd";
import CheckboxInput from "components/checkbox-input";
import CollapseCard from "components/collapse-card";
import LabelTextarea from "components/label-textarea";
import SearchPage from "components/search-page";
import SwitchInput from "components/switch-input";
import styles from "./send-sms.module.css";


const data = [
  {
    uid: 1,
    name: 'Lorem 1',
    photo: null,
    email: 'email@email.com',
    isPro: 0
  },
  {
    uid: 2,
    name: 'Lorem 2',
    photo: null,
    email: 'email@email.com',
    isPro: 1
  },
  {
    uid: 3,
    name: 'Lorem 3',
    photo: null,
    email: 'email@email.com',
    isPro: 0
  },
]

const SendSMS = () => {

  const columns = [
    {
      title: ' ',
      render: () => <CheckboxInput />
    },
    {
      title: 'Name',
      render: ({ name, photo }) => <div className="d-flex align-items-center">
        {photo && photo != "null" ? (
          <img src={photo} alt={name} className={styles.avatar} />
        ) : (
          <div className={styles.avatar} />
        )}
        <p className="mb-0">{name}</p>
      </div>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
    },
    {
      title: 'Location',
      render: ({ location }) => <span>{location ? location : '--'}</span>
    },
    {
      title: 'Pro',
      render: ({ isPro, uid }) => <SwitchInput
        name={uid}
        checked={Boolean(isPro)}
        setChecked={() => { }}
      />,
      filters: [
        {
          text: 'True',
          value: 1,
        },
        {
          text: 'False',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.isPro === value,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <div className={styles.wrapper}>
      <SearchPage title="Send SMS" isGoBack isSearchBar={false} />

      <CollapseCard title="SMS" btnText="Next">
        <div className={styles.smsContent}>
          <LabelTextarea
            isRequired={false}
            label="Message"
            placeholder="Enter text here"
            labelColor="#23314B"
            classes="mt-0"
          />
        </div>
      </CollapseCard>

      <CollapseCard
        title="Users List"
        btnText="Send SMS"
        contentClass="p-0"
        isOpen={true}
        >

        <Table
          className="mt-3 p-3 overflow-auto"
          columns={columns}
          dataSource={data}
          onChange={onChange}
        />

      </CollapseCard>
    </div>
  );
};

export default SendSMS;
