import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import cls from "classnames";
import CardSearchBar from "components/card-searchbar";
import styles from "./vendor-users.module.css";
import CheckboxInput from "components/checkbox-input";
import SwitchInput from "components/switch-input";

import moreImg from "assets/icons/more.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "antd";
import { API, PATH } from "services/axios";

const VendorUsers = ({ uid }) => {

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const navToAdd = () => {
    navigate(`/vendors/${uid}/add`);
  };

  const navToUserDetails = (e, uid) => {
    e.stopPropagation();
    navigate(`/users/${uid}`);
  };


  const getVendorDetails = async () => {
    try {
      const result = await API.get(PATH.getVendorEmployees(uid));
      console.log(result);
      if (result) {
        console.log(result);
        setData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (uid) { getVendorDetails(); }
  }, [uid]);

  console.log(uid);

  const columns = [
    {
      title: 'Name',
      render: ({ uid, firstname, lastname, photo }) => <div
        onClick={(e) => navToUserDetails(e, uid)}
        className="d-flex align-items-center pointer"
      >
        {photo && photo != "null" ? (
          <img src={photo} alt={firstname} className={styles.avatar} />
        ) : (
          <div className={styles.avatar} />
        )}
        <p className="m-0" style={{ whiteSpace: 'nowrap' }}>{firstname} {lastname}</p>
      </div>,
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
    },
    {
      title: 'Links',
      dataIndex: 'links',
      sorter: (a, b) => parseFloat(a.links) - parseFloat(b.links),
    },
    {
      title: 'Pro',
      render: ({ isPro }) => <div className={
        Boolean(isPro) ?
          (cls(styles.proDiv, { [styles.active]: true }))
          : (cls(styles.proDiv, { [styles.inactive]: true }))
      }>
        <span>{Boolean(isPro) ? 'True' : 'False'}</span>
      </div>,
      filters: [
        {
          text: 'True',
          value: 1,
        },
        {
          text: 'False',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.isPro === value,
    },
    {
      title: 'Active',
      render: ({ active }) => <SwitchInput
        checked={Boolean(active)}
      />,
      filters: [
        {
          text: 'active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.active === value,
    },
    // {
    //   title: ' ',
    //   render: () => <img src={moreImg} alt="more" />,
    // },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };


  return (
    <Card className={styles.card}>
      <CardSearchBar
        title="Users"
        rightBtnText="Add User"
        handleRightBtn={navToAdd}
        classes="m-3 mt-4"
      />

      <Table
        className="mt-3 p-3 overflow-auto"
        columns={columns}
        dataSource={data}
        onChange={onChange}
      />

    </Card>
  );
};

export default VendorUsers;
