import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, PATH } from "services/axios";
import { getUserRole } from "utils";

// Admin Login
export const adminLogin = createAsyncThunk(
  "auth/adminLogin",
  async ({ email, password }) => {
    const data = { email, password };
    return await API.post(PATH.login , data);
  }
);

// Change Password
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ currentPassword, newPassword, confirmPassword }) => {
    const data = { currentPassword, newPassword, confirmPassword };
    return await API.patch(PATH.changePassword, data);
  }
);
