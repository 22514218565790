import { createSlice } from "@reduxjs/toolkit";
import { getSales } from "./sales.async";

const initialState = {
  loading: false,
  data: {},
  subscriptions: [],
};

const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    resetSubscriptions: (state) => {
      // state.data = {...state.data, subscriptions: []}
      state.loading = false;
      state.subscriptions = [];
    },
  },
  extraReducers: {
    [getSales.pending]: (state) => {
      state.loading = true;
    },
    [getSales.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    [getSales.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
      if (Array.isArray(action.payload?.data?.subscriptions)) {
        state.subscriptions = [
          ...action.payload.data.subscriptions,
        ];
        // console.log(action.payload.data.subscriptions);
      }
    },
  },
});

export const { resetSubscriptions } = salesSlice.actions;
export default salesSlice.reducer;
