import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import styles from "./modal-empty.module.css";

const ModalEmpty = ({
  size = "md",
  paddingTop = "10vh",
  isOpen = false,
  setIsOpen,
  children,
}) => {
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={size}
      //className={styles.wrapper}
      //wrapClassName={styles.wrap}
      contentClassName={styles.content}
      //modalClassName={styles.modal}
      style={{ paddingTop }}
      backdrop={true}
    >
      {children}
    </Modal>
  );
};

ModalEmpty.propTypes = {
  size: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default ModalEmpty;
