import dashboard from "assets/sidebar/dashboard.svg";
import admins from "assets/sidebar/admins.svg";
// import users from "assets/sidebar/users.svg";
import connections from "assets/sidebar/connections.svg";
import orders from "assets/sidebar/orders.svg";
import corporates from "assets/sidebar/corporates.svg";
import sales from "assets/sidebar/sales.svg";
// import notification from "assets/sidebar/notification.svg";
import campaign from "assets/sidebar/campaign.svg";
import qr from "assets/sidebar/QR.png";
import settings from "assets/sidebar/settings.svg";
import dashboardActive from "assets/sidebar/dashboard-active.svg";
import adminsActive from "assets/sidebar/admins-active.svg";
// import usersActive from "assets/sidebar/users-active.svg";
import connectionsActive from "assets/sidebar/connections-active.svg";
import ordersActive from "assets/sidebar/orders-active.svg";
import corporatesActive from "assets/sidebar/corporates-active.svg";
// import notificationActive from "assets/sidebar/notification-active.svg";
import campaignActive from "assets/sidebar/campaign-active.svg";
import salesActive from "assets/sidebar/sales-active.svg";
import qrActive from "assets/sidebar/QR-active.png";
import settingsActive from "assets/sidebar/settings-active.svg";
import leadsIcon from "assets/sidebar/leads.svg";
import leadsActive from "assets/sidebar/leadsActive.svg";
import { getUserRole } from "utils";

let data = [];

if (getUserRole() === "Admin"){
   data = [
    {
      id: 1,
      text: "Dashboard",
      icon: dashboard,
      activeIcon: dashboardActive,
      route: "/home",
    },
    {
      id: 2,
      text: "Admins",
      icon: admins,
      activeIcon: adminsActive,
      route: "/admins",
    },
    {
      id: 3,
      text: "Users List",
      icon: admins,
      activeIcon: adminsActive,
      route: "/users",
    },
    {
      id: 4,
      text: "Connections",
      icon: connections,
      activeIcon: connectionsActive,
      route: "/connections",
    },
    // {
    //   id: 5,
    //   text: "Orders",
    //   icon: orders,
    //   activeIcon: ordersActive,
    //   route: "/orders",
    // },
    {
      id: 6,
      text: "Corporates",
      icon: corporates,
      activeIcon: corporatesActive,
      route: "/vendors",
    },
    {
      id: 7,
      text: "Campaign",
      icon: campaign,
      activeIcon: campaignActive,
      route: "/campaigns",
    },
    {
      id: 8,
      text: "Sales",
      icon: sales,
      activeIcon: salesActive,
      route: "/sales",
    },
    {
      id: 9,
      text: "Create QR Links",
      icon: qr,
      activeIcon: qrActive,
      route: "/qr-links",
    },
    {
      id: 11,
      text: "Leads",
      icon: leadsIcon,
      activeIcon: leadsActive,
      route: "/leads",
    },
    {
      id: 10,
      text: "Settings",
      icon: settings,
      activeIcon: settingsActive,
      route: "/settings",
    },
  ];
}  else{
   data = [
    {
      id: 1,
      text: "Dashboard",
      icon: dashboard,
      activeIcon: dashboardActive,
      route: "/home",
    },
    // {
    //   id: 2,
    //   text: "Admins",
    //   icon: admins,
    //   activeIcon: adminsActive,
    //   route: "/admins",
    // },
    // {
    //   id: 3,
    //   text: "Users List",
    //   icon: admins,
    //   activeIcon: adminsActive,
    //   route: "/users",
    // },
    {
      id: 4,
      text: "Connections",
      icon: connections,
      activeIcon: connectionsActive,
      route: "/connections",
    },
    // {
    //   id: 5,
    //   text: "Orders",
    //   icon: orders,
    //   activeIcon: ordersActive,
    //   route: "/orders",
    // },
    // {
    //   id: 6,
    //   text: "Corporates",
    //   icon: corporates,
    //   activeIcon: corporatesActive,
    //   route: "/vendors",
    // },
    {
      id: 6,
      text: "Employees",
      icon: corporates,
      activeIcon: corporatesActive,
      route: "/employees",
    },
    {
      id: 7,
      text: "Campaign",
      icon: campaign,
      activeIcon: campaignActive,
      route: "/campaigns",
    },
    // {
    //   id: 8,
    //   text: "Sales",
    //   icon: sales,
    //   activeIcon: salesActive,
    //   route: "/sales",
    // },
    // {
    //   id: 9,
    //   text: "Create QR Links",
    //   icon: qr,
    //   activeIcon: qrActive,
    //   route: "/qr-links",
    // },
    {
      id: 11,
      text: "Profile",
      icon: admins,
      activeIcon: adminsActive,
      route: "/profile",
    },
    {
      id: 10,
      text: "Settings",
      icon: settings,
      activeIcon: settingsActive,
      route: "/settings",
    },
  ];
}


  // const data = [
  //   {
  //     id: 1,
  //     text: "Dashboard",
  //     icon: dashboard,
  //     activeIcon: dashboardActive,
  //     route: "/home",
  //   },
  //   {
  //     id: 2,
  //     text: "Admins",
  //     icon: admins,
  //     activeIcon: adminsActive,
  //     route: "/admins",
  //   },
  //   {
  //     id: 3,
  //     text: "Users List",
  //     icon: admins,
  //     activeIcon: adminsActive,
  //     route: "/users",
  //   },
  //   // {
  //   //   id: 4,
  //   //   text: "Connections",
  //   //   icon: connections,
  //   //   activeIcon: connectionsActive,
  //   //   route: "/connections",
  //   // },
  //   // {
  //   //   id: 5,
  //   //   text: "Orders",
  //   //   icon: orders,
  //   //   activeIcon: ordersActive,
  //   //   route: "/orders",
  //   // },
  //   {
  //     id: 6,
  //     text: "Corporates",
  //     icon: corporates,
  //     activeIcon: corporatesActive,
  //     route: "/vendors",
  //   },
  //   {
  //     id: 7,
  //     text: "Campaign",
  //     icon: campaign,
  //     activeIcon: campaignActive,
  //     route: "/campaigns",
  //   },
  //   {
  //     id: 8,
  //     text: "Sales",
  //     icon: sales,
  //     activeIcon: salesActive,
  //     route: "/sales",
  //   },
  //   {
  //     id: 9,
  //     text: "Create QR Links",
  //     icon: qr,
  //     activeIcon: qrActive,
  //     route: "/qr-links",
  //   },
  //   {
  //     id: 11,
  //     text: "Leads",
  //     icon: leadsIcon,
  //     activeIcon: leadsActive,
  //     route: "/leads",
  //   },
  //   {
  //     id: 10,
  //     text: "Settings",
  //     icon: settings,
  //     activeIcon: settingsActive,
  //     route: "/settings",
  //   },
  // ];



export default data;
