import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, PATH } from "services/axios";

// Get Groups
export const getConnectionGroups = createAsyncThunk("groups/getConnectionGroups", async () => {
  return await API.get(PATH.getConnectionGroups);
});
// Get Group Details
export const getConnectionGroupDetails = createAsyncThunk(
  "groups/getConnectionGroupDetails",
  async ({ id }) => {
    return await API.get(PATH.getConnectionGroupDetails(id));
  }
);

// Add Group
export const addConnectionGroup = createAsyncThunk(
  "groups/addConnectionGroup",
  async ({ name }) => {
    const data = { name };
    return await API.post(PATH.addConnectionGroup, data);
  }
);

// Update Group
export const updateConnectionGroup = createAsyncThunk(
  "groups/updateConnectionGroup",
  async ({ id, name }) => {
    const data = { name };
    return await API.patch(PATH.updateConnectionGroup(id), data);
  }
);

// Delete Group
export const deleteConnectionGroup = createAsyncThunk(
  "groups/deleteConnectionGroup",
  async ({ id }) => {
    return await API.delete(PATH.deleteConnectionGroup(id));
  }
);

// Add Users to Group
export const addUsersToConnectionGroup = createAsyncThunk(
  "groups/addUsersToConnectionGroup",
  async ({ id, users }) => {
    const data = { users };
    return await API.post(PATH.addUserToConnectionGroup(id), data);
  }
);

// Delete Users from Group
export const deleteUsersFromConnectionGroup = createAsyncThunk(
  "groups/deleteUsersFromConnectionGroup",
  async ({ id, users }) => {
    const data = { data: { users } };
    return await API.delete(PATH.deleteUserFromConnectionGroup(id), data);
  }
);
