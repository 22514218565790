import CardButtons from 'components/card-buttons';
import LabelSelect from 'components/label-select';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'reactstrap';
import styles from './add-to-connection-group.module.css'
import { colors } from "styles/theme";
import notify from 'services/toastify';
import { addUsersToConnectionGroup, getConnectionGroups } from 'redux/slices/connectionGroup/connectionGroup.async';

const AddToConnectionsGroup = ({ setIsOpen, options, selected }) => {
    const dispatch = useDispatch();
    const [selectedGroupId, setSelectedGroupId] = useState("");
    const [loading, setLoading] = useState(false);
  
    const handleSave = () => {
      if (!selectedGroupId) {
        notify("Please select a group", 0);
        return;
      }
  
      setLoading(true);
      const data = { id: selectedGroupId, users: selected };
      dispatch(addUsersToConnectionGroup(data))
        .then((res) => {
          // console.log("Res", res);
          setLoading(false);
          const { status, message } = res.payload;
          if (status) {
            notify(message);
            dispatch(getConnectionGroups());
            setIsOpen(false);
          } else {
            notify(message, 0);
          }
        })
        .catch((err) => {
          console.log("Err", err);
          setLoading(false);
          notify("Something went wrong!!!");
        });
    };
  
    const handleChange = (e) => {
      setSelectedGroupId(e.target.value);
    };
  
    return (
      <Card className={styles.card}>
        <p className={styles.title}>Add to group</p>
        <LabelSelect
          labelColor={colors.tableText}
          label="Select group*"
          placeholder="Select group"
          classes="mt-4"
          options={options}
          value={selectedGroupId}
          onChange={handleChange}
        />
        <CardButtons
          handleCancel={() => setIsOpen(false)}
          handleSave={handleSave}
          loading={loading}
        />
      </Card>
    );
  };

export default AddToConnectionsGroup;