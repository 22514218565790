import { Container, Row, Col } from "reactstrap";
import Sidebar from "components/sidebar";

const Home = ({ children }) => {
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="p-0">
          <Sidebar />
        </Col>
        <Col md={10} className="p-0 overflow-scroll vh-100 bg--white">
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
