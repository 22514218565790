import { Card } from "reactstrap";
import styles from "./add-connection.module.css";
import SearchPage from "components/search-page";
import LabelInput from "components/label-input";
import { colors } from "styles/theme";
import CardButtons from "components/card-buttons";
import { useNavigate } from "react-router-dom";

const AddConnection = () => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  }

  
  return (
    <div className={styles.wrapper}>
      <SearchPage title="Add Connection" isGoBack />

      <Card className={styles.addCard}>
        <LabelInput
          labelColor={colors.tableText}
          label="Name"
          placeholder="Enter fullname"
          labelFontSize="14px"
          inputHeight="37px"
        />
        <LabelInput
          labelColor={colors.tableText}
          label="Email"
          placeholder="Enter email"
          labelFontSize="14px"
          inputHeight="37px"
        />
        <LabelInput
          labelColor={colors.tableText}
          label="Phone Number"
          placeholder="Enter phone no"
          isRequired={false}
          labelFontSize="14px"
          inputHeight="37px"
        />
        <LabelInput
          labelColor={colors.tableText}
          label="Job Title"
          placeholder="Enter job title"
          isRequired={false}
          labelFontSize="14px"
          inputHeight="37px"
        />

        <CardButtons handleCancel={handleCancel} />
      </Card>
    </div>
  );
};

export default AddConnection;
