import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, PATH } from "services/axios";

// Get Users
export const getUsers = createAsyncThunk(
  "users/getUsers",
  async ({ offset, limit }) => {
    return await API.get(PATH.getUsers(offset, limit));
  }
);

// Get User Details
export const getUserDetails = createAsyncThunk(
  "users/getUsers",
  async ({ uid }) => {
    return await API.get(PATH.getUserDetails(uid));
  }
);

export const updateUserIsPro = createAsyncThunk(
  "users/updateUser/isPro",
  async ({ uid, isPro }) => {
    const data = {
      uid,
      isPro,
    };
    return await API.patch(PATH.updateIsPro, data);
  }
);

export const updateUserActive = createAsyncThunk(
  "users/updateUser/active",
  async ({ uid, active }) => {
    const data = {
      uid,
      active,
    };
    return await API.patch(PATH.updateUser, data);
  }
);
