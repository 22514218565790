import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, PATH } from "services/axios";

// Get Admins
export const getAdmins = createAsyncThunk(
  "admins/getAdmins",
  async ({ offset, limit }) => {
    return await API.get(PATH.getAdmins(offset, limit));
  }
);

// Add Admin
export const addAdmin = createAsyncThunk(
  "admins/addAdmin",
  async ({ email, password, name, photo, role }) => {
    const data = { email, password, name, photo, role };
    return await API.post(PATH.addAdmin, data);
  }
);

// Update Admin
export const updateAdmin = createAsyncThunk(
  "admins/updateAdmin",
  async ({ id, email, name, photo, role, active }) => {
    const data = { email, name, photo, role, active };
    return await API.patch(PATH.updateAdmin(id), data);
  }
);

// Delete Admin
export const deleteAdmin = createAsyncThunk(
  "admins/deleteAdmin",
  async ({ id }) => {
    return await API.delete(PATH.deleteAdmin(id));
  }
);
