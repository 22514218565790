import { useState, useEffect } from "react";
import { NavItem, Nav } from "reactstrap";
import cls from "classnames";
import { useLocation, NavLink } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import styles from "./sidebar.module.css";
import data from "data/sidebar";
import logoImg from "assets/icons/logo.png";
import logoutImg from "assets/sidebar/logout.svg";
import funnelImg from "assets/sidebar/funnel.jpg";
import menuImg from "assets/icons/menu.svg";
import closeImg from "assets/icons/close.svg";
import { logout } from "redux/slices/auth/auth.slice";

const MyNavItem = ({ name, icon, routeName, activeIcon, active }) => (
  <NavItem className={styles.navItem}>
    <div className={cls(styles.box, { [styles.activeBox]: active })} />
    {/* <NavLink
      to={routeName}
      style={({ isActive }) => ({ backgroundColor: isActive ? "red" : "blue" })}
      className={styles.navLink}
    > */}
    <NavLink
      to={routeName}
      style={({ isActive }) => ({ color: isActive ? "#605bff" : "#030229" })}
      className={styles.navLink}
    >
      <div className={active ? styles.activeNavLink : ""}>
        <span className={styles.iconCover}>
          <img
            src={active ? activeIcon : icon}
            alt={name}
            className={styles.icon}
          />
        </span>
        {name}
      </div>
    </NavLink>
  </NavItem>
);

const Sidebar = () => {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    // const confirm = window.confirm("Logout!!!, Are you sure?");
    // if (confirm) {
    dispatch(logout());
    // }
  };

  useEffect(() => {
    if (user?.token) {
      const tokenInfo = jwt_decode(user.token);
      if (tokenInfo.exp * 1000 < new Date().getTime()) {
        dispatch(logout());
        // console.log("Session Expired!!!");
      } else {
        // console.log("Valid");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const isActive = (routeName) => {
    if (routeName === "/home" && pathname === "/") {
      return true;
    }
    return pathname === routeName;
  };

  let navItems = data;

  if(!user.leads) {
    navItems = navItems.filter(x=> x.route !== '/leads');
  }


  return (
    <div expand="md" className={styles.sidebar}>

      <NavLink to="/" className={styles.sidebarBrand}>
        <img height={40} src={logoImg} alt="logo" />
        <p className={styles.logoText}>Netwotap</p>
      </NavLink>

      <Nav vertical className={cls(styles.nav, { [styles.activeNav]: isOpen })}>
        {navItems.map((item) => (
          <MyNavItem
            key={item.id}
            name={item.text}
            routeName={item.route}
            icon={item.icon}
            activeIcon={item.activeIcon}
            active={isActive(item.route)}
          />
        ))}
        <div className={styles.bottomDiv}>
          {/* <div className={styles.upgradeWrapper}>
            <img
              src={funnelImg}
              alt="Upgrade Now"
              className={styles.funnelImg}
            />
            <button className={styles.upgradeBtn}>Upgrade Now</button>
          </div> */}
          <div className={styles.logoutDivPos}>
            <div className={styles.logoutDiv}>
              <div className="d-flex align-items-center">
                <div className={styles.avatar}>
                  {user?.photo && <img src={user.photo} alt="user" />}
                </div>
                <div className="ms-3 me-2">
                  <p className={styles.name}>{user?.name}</p>
                  <p className={styles.email}>{user?.email}</p>
                </div>
              </div>
              <img
                onClick={handleLogout}
                src={logoutImg}
                alt="logout"
                className={styles.logoutImg}
              />
            </div>
          </div>
        </div>
      </Nav>

      
      <button onClick={() => setIsOpen(!isOpen)} className={styles.toggleIcon}>
        <img src={isOpen ? closeImg : menuImg} alt="toggle btn" />
      </button>
    </div>
  );
};

export default Sidebar;
