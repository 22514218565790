import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import axios from "axios";

import { BASE_URL } from "services/axios";

import CommonLayout from "layouts/Common";
// SCREENS
import Login from "pages/login";
import Home from "pages/home";
import Admins from "pages/admins";
import Users from "pages/users";
import Connections from "pages/connections";
import Orders from "pages/orders";
import Sales from "pages/sales";
import QRLinks from "pages/qr-links";
import Campaigns from "pages/campaigns";
import SendNotification from "pages/send-notification";
import SendEmail from "pages/send-email";
import SendSMS from "pages/send-sms";
import Settings from "pages/settings";
import UserDetails from "pages/user-details";
import PrivateRoute from "handle-routes/PrivateRoute";
import AddConnection from "pages/connections/add-connection";
import AddLink from "pages/qr-links/add-link";
import AddUser from "pages/users/add-user";
import GroupDetails from "pages/group-details";
import RedirectTo from "pages/redirect-to";
import Vendors from "pages/vendors";
import VendorDetails from "pages/vendor-details";
import EditVendorDetails from "pages/vendor-details/EditVendorDetails";
import Leads from 'pages/leads';

import 'antd/dist/antd.css';
import AdminRoute from "handle-routes/AdminRoute";
import VendorsEmployees from "pages/VendorsEmployees/VendorsEmployees";
import VendorProfile from "pages/VendorProfile/VendorProfile";
import ConnectionGroupDetails from "pages/connectionGroupDetails/ConnectionGroupDetails";

const App = () => {
  console.log = function() {}

  const { user } = useSelector((state) => state.auth, shallowEqual);

  // axios.defaults.headers['X-Key'] = APP_SECRET;
  // axios.defaults.headers.Accept = "application/json";
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.timeout = 10000;

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = user?.token
      ? `Bearer ${user.token}`
      : null;
  }, [user]);

  // // Add a request interceptor
  // axios.interceptors.request.use(
  //   function (config) {
  //     // Do something before request is sent
  //     return config;
  //   },
  //   function (error) {
  //     // Do something with request error
  //     return Promise.reject(error);
  //   }
  // );

  // // Add a response interceptor
  // axios.interceptors.response.use(
  //   function (response) {
  //     // Any status code that lie within the range of 2xx cause this function to trigger
  //     // Do something with response data
  //     return response;
  //   },
  //   function (error) {
  //     // Any status codes that falls outside the range of 2xx cause this function to trigger
  //     // Do something with response error
  //     return Promise.reject(error);
  //   }
  // );

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="redirect"
          element={<CommonLayout children={<RedirectTo />} />}
        />
        <Route path="" element={<PrivateRoute user={user} />}>
          <Route path="home" element={<Home />} />

          {/* for admin  */}
          <Route path="" element={<AdminRoute user={user} />}>
            <Route path="admins" element={<Admins />} />
            <Route path="users" element={<Users />} />
            <Route path="users/add-edit" element={<AddUser />} />
          </Route>


          <Route path="users/:id" element={<UserDetails />} />
          <Route path="users/group/:id" element={<GroupDetails />} />
          <Route path="connections" element={<Connections />} />
          <Route path="connections/add-edit" element={<AddConnection />} />
          <Route path="connections/group/:id" element={<ConnectionGroupDetails />} />

          {/* for admin  */}
          <Route path="" element={<AdminRoute user={user} />}>
            <Route path="orders" element={<Orders />} />
            <Route path="vendors" element={<Vendors />} />
            <Route path="vendors/add" element={<AddUser />} />
          </Route>


          <Route path="vendors/:id" element={<VendorDetails />} />
          <Route path="vendors/edit/:id" element={<EditVendorDetails />} />
          <Route path="vendors/:id/add" element={<AddUser />} />

          <Route path="employees" element={<VendorsEmployees />} />

          {/* for admin  */}
          <Route path="" element={<AdminRoute user={user} />}>
            <Route path="sales" element={<Sales />} />
            <Route path="leads" element={<Leads />} />
          </Route>


          <Route path="campaigns" element={<Campaigns />} />
          <Route
            path="campaigns/send-notification"
            element={<SendNotification />}
          />
          <Route path="campaigns/send-email" element={<SendEmail />} />
          <Route path="campaigns/send-sms" element={<SendSMS />} />

          {/* for admin  */}
          <Route path="" element={<AdminRoute user={user} />}>
            <Route path="qr-links" element={<QRLinks />} />
            <Route path="qr-links/add-edit" element={<AddLink />} />
          </Route>


          <Route path="profile" element={<VendorProfile />} />
          <Route path="settings" element={<Settings />} />
          <Route index element={<Home />} />
        </Route>

        <Route path="login" element={<CommonLayout children={<Login />} />} />

        <Route
          path="*"
          element={<CommonLayout children={<h1>NOT FOUND (404)</h1>} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
