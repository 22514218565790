import React, { useEffect, useState, useRef, useMemo } from "react";
import { Card } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import cls from "classnames";
import styles from "./campaigns.module.css";
import SearchPage from "components/search-page";
import { getCampaigns } from "redux/slices/campaigns/campaigns.async";
import { resetCampaigns } from "redux/slices/campaigns/campaigns.slice";
import { trimString } from "utils";
import { Table } from "antd";
import { useSearchParams } from "react-router-dom";

const limit = 10;

const Campaigns = () => {
  const { campaignsList } = useSelector(
    (state) => state.campaigns,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState("");

  // START INFINITE SCROLL
  const offset = useRef(0);


  useEffect(() => {
    dispatch(getCampaigns({ offset: offset.current, limit }));

    return () => {
      dispatch(resetCampaigns());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // START LOCAL SEARCH
  const searchForData = (arr, s) => {
    s = trimString(s);
    return arr.filter((item, index) => {
      return item.title.toLowerCase() && item.title.toLowerCase().includes(s);
    });
  };

  // const searchData = useMemo(() => {
  //   return search.length > 0
  //     ? searchForData(campaignsList, search.toLowerCase())
  //     : campaignsList;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search, campaignsList?.length]);
  // END LOCAL SEARCH


  const columns = [
    {
      title: 'Campaign',
      width: '15%',
      render: (item) => <span style={{ whiteSpace: 'nowrap' }}>{item.title}</span>,
      sorter: (a, b) => a.title.localeCompare(b.title)
    },
    {
      title: 'Date',
      width: '15%',
      render: (item) => <span style={{ whiteSpace: 'nowrap' }} className="dim--text">
        {moment(item.createdAt).format("DD MMM YYYY")}
      </span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
    },
    {
      title: 'Type',
      width: '20%',
      render: (item) => <div
        className={cls(
          styles.type,
          styles[item.type.toLowerCase()]
        )}
      >
        {item.type}
      </div>,
      filters: [
        {
          text: 'Notification',
          value: 'Notification',
        },
        {
          text: 'Email',
          value: 'Email',
        },
      ],
      onFilter: (value, record) => record.type.toLowerCase() === value.toLowerCase(),
    },
    {
      title: 'Sends',
      width: '10%',
      render: (item) => <span style={{ whiteSpace: 'nowrap' }} className="dim--text">{item.sends || "--"} {item.sends > 1 ? 'Sends' : 'Send'}</span>
    }
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setSearchParams({
      page: pagination.current,
      size: pagination.pageSize
    });
    dispatch(getCampaigns({
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize
    }));
  };

  return (
    <div className={styles.wrapper}>
      <SearchPage
        title="Campaigns"
        isCampaign
        search={search}
        setSearch={setSearch}
      />

      <Card className="border-0">

        <Table
          rowKey={'id'}
          className="mt-3 p-3 overflow-auto"
          columns={columns}
          dataSource={search.length > 0
            ? searchForData(campaignsList, search.toLowerCase())
            : campaignsList}
          onChange={onChange}
          pagination={{
            defaultPageSize: Number(searchParams.get('size')) || 10,
            defaultCurrent: Number(searchParams.get('page')) || 1,
            total: campaignsList ? campaignsList[0]?.total : 10
          }}
        />


      </Card>
    </div>
  );
};

export default Campaigns;
