import CheckboxInput from "components/checkbox-input";
import React from "react";
import { Card } from "reactstrap";
import { colors } from "styles/theme";
import styles from "./count-card.module.css";

const CountCard = ({
  photo,
  count = 0,
  name,
  backgroundColor = colors.white,
  isSelected,
}) => {
  return (
    <Card className={styles.card} style={{ backgroundColor }}>
      <img src={photo} alt={name} className={styles.photo} />
      <div className="d-flex flex-column justify-content-center">
        <p className={styles.count}>{count}</p>
        <p className={styles.name} style={{whiteSpace:'nowrap'}}>{name}</p>
      </div>
      {isSelected && (
        <div className={styles.checkboxPos}>
          <CheckboxInput name="group_card" checked={true} />
        </div>
      )}
    </Card>
  );
};

export default CountCard;
