export const validateEmail = (email) => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(String(email).toLowerCase());
};

export const validatePhNumber = (phNumber) => {
  const regexp = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{8,16})$/;
  return regexp.test(phNumber);
};

export const getUserRole = () => {
  switch (window.location.origin) {
    case "https://admin.netwotap.com":
      return "Admin";
    case "https://vendors.netwotap.com":
      return "Vendor"
    default:
      return "Admin";
  }
  // return "Vendor"
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const validateText = (text) => {
  const regexp = /^[a-zA-Z]+$/;
  return regexp.test(text);
};

export const validateTaxNumber = (taxId) => {
  const regexp = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  return regexp.test(taxId);
};

export const getQueryParams = () =>
  window.location.search
    .replace("?", "")
    .split("&")
    .reduce(
      (r, e) => ((r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])), r),
      {}
    );

export const getIdx = (arrayData, id) => {
  return arrayData.findIndex((x) => x.id === id);
};

// File Download
export const downloadFile = (url, filename) => {
  fetch(url).then(function (t) {
    return t.blob().then((b) => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    });
  });
};

export const trimString = (s) => {
  var l = 0,
    r = s.length - 1;
  while (l < s.length && s[l] === " ") l++;
  while (r > l && s[r] === " ") r -= 1;
  return s.substring(l, r + 1);
};

// export const uniqueArray = (array) => {
//   let newArray = [];
//   const seen = new Set();
//   array.filter((el) => {
//     const duplicate = seen.has(el.id);
//     seen.add(el.id);
//     if (!duplicate) {
//       newArray.push(el);
//     }
//   });
//   return newArray;
// };

// export const objToArray = (obj) => {
//   const newArray = [];
//   Object.keys(obj).map((key) => {
//     newArray.push({ name: key, value: obj[key] });
//   });

//   return newArray;
// };

export const getFileType = (file) => {
  if (file) {
    const splitNames = file.name.split(".");
    return splitNames[splitNames.length - 1];
  } else {
    return null;
  }
};

export const capitalize = (str = "") => {
  const lowerStr = str.toLowerCase();
  return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1);
};

export const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const secToMin = (sec) => {
  if (sec) {
    const m = Math.floor(sec / 60);
    const s = sec - m * 60;
    return m > 0
      ? `${m} ${m > 1 ? "mins" : "min"}`
      : `${s} ${s > 1 ? "secs" : "sec"}`;
  } else {
    return 0;
  }

  // return `${m} ${m > 1 ? "mins" : "min"} ${s} ${s > 1 ? "secs" : "sec"}`;

  // OTHER WAY
  // const duration = moment.duration(sec, 'seconds');
  // const h = duration.hours();
  // const m = duration.minutes();
  // const s = duration.seconds();

  // return `${m} min`
};

export function isIpadOS() {
  if (
    /iPad/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints === 5)
  ) {
    return true;
  } else {
    return false;
  }
}

export function isLandscape() {
  return window.orientation === 90 || window.orientation === -90;
}

export const getRandomColor = () =>
  "#" + parseInt(Math.random() * 0xffffff).toString(16);

  export  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }