import React, { useState } from "react";
import { Card } from "reactstrap";
import styles from "./user-preview-card.module.css";

import copyImg from "assets/icons/copy-black.svg";

const UserPreviewCard = ({ link }) => {
   const handleCopy = () => {
    navigator.clipboard.writeText(link);
  };

  return (
    <Card className={styles.card}>
      <div className={styles.copyDiv}>
        <p>{link}</p>
        <img src={copyImg} alt="Copy link" onClick={handleCopy} />
      </div>
    </Card>
  );
};

export default UserPreviewCard;
